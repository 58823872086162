import axios from "axios";

const baseURL = process.env.REACT_APP_VIEWS_API_URL;
const FORM_DATA_URL = [
  "whisper/paired",
  "whisper/single",
  "image",
  "video",
  "consultingApply",
];

const request = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

request.interceptors.request.use(
  // 요청 보내기 전 처리
  function (config) {
    if (FORM_DATA_URL.includes(config.url)) {
      config.headers["Content-Type"] = "multipart/form-data";

      if (config.url !== "video" && config.url !== "consultingApply") {
        config.baseURL = process.env.REACT_APP_APP_API_URL;
      }
    }

    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "access_token"
    )}`;

    return config;
  },
  // 요청 에러 처리
  function (error) {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  // 응답 받은 후 처리
  function (response) {
    return response;
  },
  // 응답 에러 처리
  function (error) {
    // return error;
    return Promise.reject(error);
  }
);

async function requestWithMethod(url, method, body) {
  try {
    const response = await request({
      method,
      url,
      data: body,
    });

    return response.data || response;
  } catch (error) {
    throw error;
  }
}

// GET 메서드
async function get(url) {
  return requestWithMethod(url, "get");
}

// POST 메서드
async function post(url, body) {
  return requestWithMethod(url, "post", body);
}

// PATCH 메서드
async function patch(url, body) {
  return requestWithMethod(url, "patch", body);
}

// PUT 메서드
async function put(url, body) {
  return requestWithMethod(url, "put", body);
}

// DELETE 메서드
async function remove(url, body) {
  return requestWithMethod(url, "delete", body);
}

export default {
  get,
  post,
  patch,
  put,
  remove,
};
