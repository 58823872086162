// 아이디, 비밀번호 찾기 페이지

//* hooks
import { useState } from "react";
import { useLocation } from "react-router-dom";

//* style
import { AuthContainer } from "@styles/common";

//* components
import AuthNav from "@components/auth/AuthNav";
import FoundID from "@/components/auth/FoundID";
import ChangePW from "@/components/auth/ChangePW";
import FindIDForm from "@/components/auth/FindIDForm";
import FindPWForm from "@/components/auth/FindPWForm";

function FindAccountPage() {
  //* state
  const [id, setId] = useState(""); // 아이디

  const path = useLocation().pathname;

  return (
    <AuthContainer>
      {path === "/fdID" ? (
        <>
          <h1>아이디 찾기</h1>
          {id ? <FoundID id={id} /> : <FindIDForm setId={setId} />}
        </>
      ) : (
        <>
          <h1>비밀번호 찾기</h1>
          {id ? <ChangePW id={id} /> : <FindPWForm setId={setId} />}
        </>
      )}
      <AuthNav id={path === "/fdID" ? id : null} />
    </AuthContainer>
  );
}

export default FindAccountPage;
