// 상담사 - 알림 키워드 리스트

//* hooks
import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";

//* style
import { CommonButton } from "@styles/common";

//* component
import DataTable from "@components/common/DataTable";

function NotificationKeywordList({ tableData, deleteKeyword }) {
  const columnHelper = createColumnHelper();
  const columns = useMemo(
    () => [
      columnHelper.accessor("NO", {
        header: "NO",
        size: 10,
        cell: ({ row, table }) =>
          (table
            .getSortedRowModel()
            ?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1,
      }),
      columnHelper.accessor("keyword", {
        header: "키워드",
        size: 600,
      }),
      columnHelper.accessor("delete", {
        header: "삭제",
        size: 10,
        cell: ({ row }) => (
          <CommonButton
            width="100%"
            style={{ backgroundColor: "#FF0000" }}
            onClick={() => deleteKeyword(row.original.seq)}
          >
            삭제
          </CommonButton>
        ),
      }),
    ],
    []
  );

  return (
    <DataTable columns={columns} type="noticeKeyword" tableData={tableData} />
  );
}

export default NotificationKeywordList;
