// 공통 header

//* hooks
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";

//* api
import { api } from "@api";

function Header() {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const username = localStorage.getItem("user_name");
  const usertype = localStorage.getItem("user_type");

  const headerMenu = [
    { name: "상담신청", path: "/consultation-application" },
    { name: "상담일지", path: "/today-consultation-diary" },
    { name: "상담이력", path: "/consultation-history" },
    { name: "상담 리포트", path: "/consultation-report" },
    { name: "설문관리", path: "/" },
    { name: "알림 키워드", path: "/notification-keyword" },
  ];

  const showAlert = (type) => {
    return window.alert(`${type}만 접근 가능합니다.`);
  };

  const navigateHandler = (path) => {
    const accessControl = {
      "/consultation-application": "내담자",
      "/today-consultation-diary": "상담사",
      "/consultation-history": "상담사",
      "/consultation-report": "상담사",
      "/notification-keyword": "상담사",
      "/question-bank": "상담사",
      내정보: "내담자",
    };

    if (accessControl[path]) {
      if (usertype !== accessControl[path]) {
        showAlert(accessControl[path] === "상담사" ? "상담사" : "내담자");
      } else {
        navigate(path === "내정보" ? "/my-consultation" : path);
      }
    } else {
      navigate(path);
    }
  };

  const logout = async () => {
    try {
      await api.GET_LOGOUT();

      localStorage.removeItem("access_token");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_type");
      navigate("/login");
    } catch (err) {
      console.log("로그아웃 err", err);
    }
  };

  const getMenuClass = (menuPath) => {
    if (menuPath === path) {
      return "active";
    } else if (
      path === "/all-consultation-diary" &&
      menuPath === "/today-consultation-diary"
    ) {
      return "active";
    } else if (
      path.split("/")[1] === "consultation-report" &&
      menuPath === "/consultation-report"
    ) {
      return "active";
    } else if (
      (path === "/question-bank" || path === "/question-diagnosis") &&
      menuPath === "/"
    ) {
      return "active";
    }
  };

  return (
    <HeaderContainer>
      <h1>
        <span>정신건강 상담</span> <span>시스템</span>
      </h1>

      <ul>
        {headerMenu.map((menu) => (
          <li
            key={menu.name}
            onClick={() =>
              menu.name !== "설문관리" && navigateHandler(menu.path)
            }
            className={getMenuClass(menu.path)}
          >
            {menu.name}
            {menu.name === "설문관리" && (
              <ul className="question-content">
                <li onClick={() => navigateHandler("/question-bank")}>
                  문제은행
                </li>
                <li onClick={() => navigateHandler("/question-diagnosis")}>
                  진단검사 출제
                </li>
              </ul>
            )}
          </li>
        ))}
      </ul>

      <ul>
        <li onClick={() => navigateHandler("내정보")}>{username}</li>
        <li onClick={logout}>로그아웃</li>
      </ul>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.header`
  height: 100px;
  padding: 0rem 4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.secondary};

  h1 {
    display: flex;
    font-size: 1.4rem;
    align-items: center;
    flex-direction: column;
    color: ${({ theme }) => theme.color.primary};
  }

  ul {
    display: flex;

    li {
      cursor: pointer;
      font-weight: 700;
      margin: 0rem 2rem;
      font-size: 1.2rem;
      position: relative;
      color: ${({ theme }) => theme.fontColor["text-3"]};
    }

    .active {
      color: ${({ theme }) => theme.color["active"]};
    }
  }

  .question-content {
    left: 50%;
    z-index: 1;
    width: 10rem;
    display: none;
    padding: 0.2rem;
    position: absolute;
    border: 1px solid #ccc;
    flex-direction: column;
    background-color: #fff;
    transform: translateX(-50%);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

    li {
      width: 100%;
      display: flex;
      font-size: 1rem;
      margin: 0.2rem 0;
      padding: 0.4rem 0;
      align-items: center;
      justify-content: center;
    }

    li:hover {
      background-color: ${({ theme }) => theme.color.secondary};
    }
  }

  li:hover .question-content,
  .question-content:hover {
    display: flex;
  }

  > :nth-child(3) {
    li {
      font-size: 0.8rem;
      margin: 0rem 1rem;
    }
  }
`;

export default Header;
