// 공통 바 차트

import { ResponsiveBar } from "@nivo/bar";

function BarChart({ data }) {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ResponsiveBar
        data={data}
        padding={0.3}
        animate={false}
        keys={["count"]}
        indexBy="keyword"
        enableGridY={false}
        colors={["#ffb254"]}
        valueFormat={(value) => `${value}%`}
        margin={{ top: 10, right: 0, bottom: 74, left: 0 }}
      />
    </div>
  );
}

export default BarChart;
