// 상담사 - 오늘의상담, 상담요청전체, 상담이력, 상담 리포트 공통 리스트

//* hooks
import "moment/locale/ko";
import moment from "moment";
import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";

//* component
import DataTable from "@components/common/DataTable";

function ConsultationList({ type, tableData }) {
  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    const baseColumns = [
      columnHelper.accessor("NO", {
        header: "NO",
        size: 10,
        cell: ({ row, table }) =>
          (table
            .getSortedRowModel()
            ?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1,
      }),
      columnHelper.accessor("username", {
        header: "상담자",
        size: 80,
      }),
      columnHelper.accessor("counsel_dt", {
        header: "상담일시",
        size: 80,
      }),
      columnHelper.accessor("counsel_way", {
        header: "상담방법",
        size: 80,
      }),
      columnHelper.accessor("subject", {
        header: "상담명",
        size: 280,
      }),
    ];

    if (type !== "report") {
      baseColumns.push(
        columnHelper.accessor(type === "history" ? "" : "inserted_on", {
          header: type === "history" ? "차기 상담 여부" : "상담신청일",
          size: 100,
          cell: ({ row }) => {
            return type === "history"
              ? row.original.is_next_sch
              : row.original.inserted_on;
          },
        })
      );
    }

    return baseColumns;
  }, [type]);

  return (
    <>
      {type === "today" && (
        <h2 style={{ marginTop: "1rem", fontSize: "1.2rem" }}>
          {moment(new Date()).locale("ko").format("YYYY년 MM월 DD일")}
        </h2>
      )}
      <DataTable type={type} columns={columns} tableData={tableData} />
    </>
  );
}

export default ConsultationList;
