// common styled component 정의
import styled from "styled-components";

//* common
// common 버튼
export const CommonButton = styled.button`
  color: #fff;
  font-weight: bold;
  padding: 0.6rem;
  width: ${(props) => props.width};
  font-size: ${(props) => props.fontSize};
  border: 1px solid ${({ theme }) => theme.color.border};
  background-color: ${({ theme }) => theme.color.primary};

  &:disabled {
    color: #666;
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

// common 필수값 미입력시
export const CommonRequiedMessage = styled.p`
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.color.error};
`;

// common 나의상담 상세(내담자), 상담일지.상담이력 상세(상담사)
export const CommonConsultingDetailContainer = styled.div`
  margin: 2rem 0rem;

  #printWrap {
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-end;
  }

  h2 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  table {
    width: 100%;
    margin-bottom: 3rem;
    border-collapse: collapse;

    thead tr th {
      text-align: left;
      font-weight: normal;
      width: ${(props) => props.width};
    }

    td,
    th {
      border: 1px solid ${({ theme }) => theme.color.border};
      padding: 0.8rem;
    }

    .key {
      font-weight: 600;
      background-color: ${({ theme }) => theme.color.secondary};
    }

    textarea {
      width: 100%;
      resize: none;
      height: 5rem;
      font-size: 1rem;
    }

    #reservationContainer {
      display: flex;
      justify-content: space-between;

      #reservationChoice {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          margin-bottom: 1rem;
        }

        #reservationInput {
          display: flex;
          justify-content: center;

          input {
            margin-right: 0.2rem;
          }

          :nth-child(2) {
            margin-right: 1rem;
          }
        }
      }

      #reservationDate {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .reservation-date {
          font-weight: bold;
          margin-bottom: 1rem;
        }

        .reservation-hour {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
  }
`;

// common 검색
export const CommonSearchBar = styled.div`
  display: flex;
  padding: 1rem;
  margin: 1rem 0;
  align-items: center;
  border-radius: 0.4rem;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.color.border};

  #dateContainer {
    position: relative;

    #datePicker {
      top: 3rem;
      position: absolute;
    }

    input {
      margin: 0rem 0.6rem;
    }
  }

  label {
    margin-right: 1rem;
  }

  select {
    width: 10rem;
    padding: 0.6rem;
    border-radius: 0.4rem;
    border: 1px solid ${({ theme }) => theme.color.border};
  }

  #division {
    margin-left: 0.4rem;
  }
`;

// common 모달
export const Modal = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  .modal-container {
    width: 90%;
    height: 90%;
    position: relative;
    border-radius: 0.4rem;
    background-color: #fff;
  }

  .modal-header {
    display: flex;
    padding: 0.6rem 1.4rem;
    justify-content: space-between;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    background-color: ${({ theme }) => theme.color["primary-2"]};

    h1 {
      font-size: 1.2rem;
    }

    button {
      font-size: 1rem;
      font-weight: 600;
      background: none;
    }
  }

  .modal-content {
    display: flex;
    padding: 1.4rem;
    flex-direction: column;
    height: calc(100% - 3rem);
    justify-content: space-between;
  }

  .question-list-wrap,
  .question-content-wrap {
    height: calc(50% - 1rem);
  }

  .question-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 1.2rem;
    }

    span {
      font-size: 0.8rem;
      margin-left: 0.4rem;
    }
  }

  .button-container {
    gap: 0.2rem;
    display: flex;

    .add-btn {
      background-color: ${({ theme }) => theme.color["primary-2"]};
    }

    .remove-btn {
      background-color: ${({ theme }) => theme.color.error};
    }
  }

  .select-container {
    display: flex;
    align-items: center;

    select {
      margin: 0 1rem;
    }
  }

  .question-list,
  .question-content {
    height: calc(100% - 2.5rem);
    overflow-y: auto;
  }

  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    .key {
      width: 12rem;
      font-weight: 600;
      background-color: ${({ theme }) => theme.color.secondary};
    }

    input[type="text"],
    textarea {
      width: 100%;
    }

    textarea {
      resize: none;
      height: 5rem;
    }

    span {
      color: ${({ theme }) => theme.color.error};
      font-weight: bold;
    }

    input[type="radio"] {
      margin-right: 0.2rem;
    }

    select {
      width: 100%;
    }

    label {
      margin-right: 1rem;
    }
  }
  table td,
  th {
    padding: 0.8rem;
    border: 1px solid ${({ theme }) => theme.color.border};
  }
`;

// 설문관리 리스트 header
export const QuestionListHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;

  h2 {
    font-size: 1.2rem;
  }

  div {
    gap: 0.2rem;
    display: flex;

    button:nth-child(1) {
      background-color: ${({ theme }) => theme.color["primary-2"]};
    }

    button:nth-child(3) {
      background-color: ${({ theme }) => theme.color.error};
    }
  }
`;

// 설문관리 관리
export const QuestionManage = styled.div`
  padding-top: 1rem;
  margin-bottom: 4rem;
  border-top: 1px solid ${({ theme }) => theme.color.border};

  span {
    color: ${({ theme }) => theme.color.error};
    font-weight: bold;
  }

  #questionManageHeader {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;

      h2 {
        font-size: 1.2rem;
        margin-right: 1rem;
      }
    }

    .btn-wrap {
      gap: 0.2rem;
      display: flex;

      button:nth-child(1) {
        background-color: ${({ theme }) => theme.color["primary-2"]};
      }

      button:nth-child(3) {
        background-color: ${({ theme }) => theme.color.error};
      }
    }
  }

  #questionManageContent {
    table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;

      .key {
        width: 20%;
        font-weight: 600;
        background-color: ${({ theme }) => theme.color.secondary};
      }

      input[type="text"],
      textarea {
        width: 100%;
      }

      textarea {
        resize: none;
        height: 5rem;
      }

      input[type="radio"] {
        margin-right: 0.2rem;
      }

      select {
        width: 100%;
      }

      label {
        margin-right: 1rem;
      }
    }
    table td,
    th {
      padding: 0.8rem;
      border: 1px solid ${({ theme }) => theme.color.border};
    }

    .inspection_target {
      width: 50%;
      display: flex;
      align-items: center;

      button {
        width: 4rem;
      }
    }

    .make-inspection {
      display: flex;
      align-items: center;

      button {
        margin-right: 1rem;
      }

      .remove {
        margin-left: 1rem;
        background-color: ${({ theme }) => theme.color.error};
      }
    }
  }
`;

// 내담자 감정 그래프 & stt 영역
export const ConsultationAreaWrap = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid ${({ theme }) => theme.color.border};

  .consultation-area {
    width: 50%;
    border-right: 1px solid ${({ theme }) => theme.color.border};

    .key {
      padding: 0.8rem;
      font-weight: 600;
      text-align: center;
      background-color: ${({ theme }) => theme.color.secondary};
      border-bottom: 1px solid ${({ theme }) => theme.color.border};
    }

    .consultation-content {
      height: 480px;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        position: relative;
      }
    }

    .disabled {
      background-color: #ddd;
    }

    #consultationContentWrap {
      height: 1000px;
      overflow: auto;

      #consultationContent {
        width: 100%;
        display: flex;
        flex-direction: column;

        .speech-bubble {
          width: 50%;
          margin: 0.4rem;
          padding: 0.4rem;
          position: relative;
          border-radius: 0.4rem;
          border: 1px solid ${({ theme }) => theme.color.border};

          .caution {
            color: ${({ theme }) => theme.color.caution};
          }
          .danger {
            color: ${({ theme }) => theme.color.danger};
          }
          .serious {
            color: ${({ theme }) => theme.color.error};
          }

          .date {
            bottom: 0;
            font-size: 0.8rem;
            position: absolute;
          }
        }

        .speech-bubble:nth-child(even) {
          .date {
            right: -3.2rem;
          }
        }

        .speech-bubble:nth-child(odd) {
          align-self: flex-end;

          .date {
            left: -3.2rem;
          }
        }
      }
    }

    #consultationVideo {
      height: 480px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .history-chart {
    height: 400px;
  }

  :last-child {
    border-right: none;
  }

  canvas {
    position: absolute;
  }
`;

// AUTH 관련 제외한 페이지 레이아웃
export const MainLayout = styled.div`
  margin: 0rem 3rem;
  position: relative;
  width: calc(100vw - 6rem);

  #myInfoArea {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  #applicationTitle {
    display: flex;
    margin-top: 2rem;
    font-size: 1.4rem;
    align-items: center;
    color: ${({ theme }) => theme.fontColor.text};

    span {
      font-size: 0.8rem;
      margin-left: 1rem;
      color: ${({ theme }) => theme.fontColor.text};
    }
  }

  #listButton {
    width: 100%;
    display: flex;
    margin: 2rem 0rem;
    justify-content: center;
  }
`;

// Tab
export const CommonTab = styled.div`
  width: 100%;
  height: 4.4rem;
  border-bottom: 1px solid ${({ theme }) => theme.fontColor["text-2"]};

  ul {
    display: flex;
    position: relative;

    li {
      color: #333;
      cursor: pointer;
      padding: 1.4rem 2rem;
      font-weight: 700;
    }

    .active {
      color: ${({ theme }) => theme.color.primary};
    }

    .inactive {
      color: #555;
    }
  }
`;

// 예약 시간
export const HourChip = styled.div`
  div {
    margin: 0.4rem;
    padding: 0.4rem;
    cursor: pointer;
    margin-left: 0rem;
    border-radius: 0.4rem;
    border: 1px solid ${({ theme }) => theme.color.border};
  }

  .active {
    color: #fff;
    background-color: ${({ theme }) => theme.color.primary};
  }

  .disabled {
    cursor: not-allowed;
    background-color: #ddd;
  }
`;

//* AUTH 관련
// AUTH 공통 container
export const AuthContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1rem;
    margin: 1.8rem 0rem;
    text-align: center;
    color: ${({ theme }) => theme.fontColor.text};
  }

  #userID {
    font-size: 2rem;
    font-weight: bold;
    color: ${({ theme }) => theme.color.primary};
  }
`;

// 아이디, 비밀번호 찾기 공통 container
export const FindIdPwContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  form {
    width: 350px;

    #findInput {
      margin-bottom: 2.4rem;

      label {
        display: flex;
        align-items: center;
        margin: 0.6rem 0rem;
        justify-content: space-between;

        input {
          width: 70%;
        }
      }
    }
  }
`;
