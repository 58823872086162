// 내담자 내정보 - 나의 상담 리스트 - 상세

//* hooks
import { useState } from "react";

//* style
import { CommonConsultingDetailContainer } from "@styles/common";

//* components
import Tab from "@components/common/Tab";

function ConsultationDetailPage({ seq, consultationDetailData }) {
  //* state
  const [activeTab, setActiveTab] = useState(0);

  const tabList = ["나의 상담", "내 정보"];

  const isShowConsultationContent = () => {
    const status = consultationDetailData.status;

    const isProgress = status === "상담중";
    const isWaiting = status === "상담대기";
    const isFinished = status === "상담완료";
    const isOnline = consultationDetailData.counsel_way === "온라인";

    if (isOnline) {
      if (isWaiting || isProgress) {
        return false;
      } else if (isFinished) {
        return true;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <Tab
        tabList={tabList}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      <CommonConsultingDetailContainer width="16.6%">
        <h2>상담 신청 내용</h2>
        <table>
          <thead>
            <tr>
              <th className="key">상담신청일</th>
              <th>{consultationDetailData.inserted_on}</th>
              <th className="key">상담방법</th>
              <th>{consultationDetailData.counsel_way}</th>
              <th className="key">상담상태</th>
              <th>{consultationDetailData.status}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="key">상담명</td>
              <td colSpan="5">{consultationDetailData.subject}</td>
            </tr>
            <tr>
              <td className="key">상담신청 내용</td>
              <td colSpan="5">{consultationDetailData.request_details}</td>
            </tr>
          </tbody>
        </table>

        <h2>상담 내용</h2>
        <table>
          <thead>
            <tr>
              <th className="key">상담일시</th>
              <th>{consultationDetailData.counsel_dt}</th>
              <th className="key">상담사</th>
              <th>{consultationDetailData.admin_username}</th>
              <th className="key">차기 상담일시</th>
              <th>{consultationDetailData.is_next_sch}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="key">상담 조치 내용</td>
              <td colSpan="5">{consultationDetailData.action_details}</td>
            </tr>
            {isShowConsultationContent() && (
              <tr>
                <td className="key">상담 내용</td>
                <td colSpan="5">
                  {consultationDetailData.status === "상담완료" && (
                    <video style={{ width: 600, height: 480 }} controls>
                      <source
                        src={`${process.env.REACT_APP_VIEWS_API_URL}videoPlay?seq=${seq}`}
                      />
                    </video>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </CommonConsultingDetailContainer>
    </>
  );
}

export default ConsultationDetailPage;
