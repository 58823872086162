// 설문관리 - 문제은행 관리

//* hooks
import React from "react";

//* style
import { QuestionManage, CommonButton } from "@styles/common";

function QuestionBankContent({
  mainSeq,
  useType,
  questionType,
  inspectionType,
  questionManage,
  addMainQuestion,
  saveMainQuestion,
  questionCategory,
  setQuestionManage,
  deleteMainQuestion,
}) {
  return (
    <QuestionManage>
      <div id="questionManageHeader">
        <div className="title">
          <h2>문제은행 관리</h2>
          <ul>
            <li>
              <span>*</span>표시는 필수 입력 항목 입니다.
            </li>
            <li>문제은행 별 문항은 최대 20개 까지 등록할 수 있습니다.</li>
          </ul>
        </div>
        <div className="btn-wrap">
          <CommonButton onClick={addMainQuestion}>추가</CommonButton>
          <CommonButton onClick={saveMainQuestion}>저장</CommonButton>
          <CommonButton onClick={deleteMainQuestion}>삭제</CommonButton>
        </div>
      </div>
      <div id="questionManageContent">
        <table>
          <thead></thead>
          <tbody>
            <tr>
              <td style={{ width: "20%" }} className="key">
                문제은행 명<span>*</span>
              </td>
              <td colSpan="3">
                {mainSeq ? (
                  questionManage.question_name
                ) : (
                  <input
                    type="text"
                    maxLength={20}
                    value={questionManage.question_name}
                    onChange={(e) =>
                      setQuestionManage((prev) => ({
                        ...prev,
                        question_name: e.target.value,
                      }))
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <td className="key">
                사용여부<span>*</span>
              </td>
              <td colSpan="3">
                {mainSeq ? (
                  <div>
                    {
                      useType.find(
                        (yn) => yn.detail_code === questionManage["use_yn"]
                      ).code_name
                    }
                  </div>
                ) : (
                  useType.map(
                    (yn) =>
                      yn.code_name !== "전체" && (
                        <React.Fragment key={yn.code_name}>
                          <input
                            name="yn"
                            type="radio"
                            id={yn.code_name}
                            value={yn.detail_code}
                            checked={
                              questionManage["use_yn"] === yn.detail_code
                            }
                            onChange={(e) => {
                              setQuestionManage((prev) => ({
                                ...prev,
                                use_yn: e.target.value,
                              }));
                            }}
                          />
                          <label htmlFor={yn.code_name}>{yn.code_name}</label>
                        </React.Fragment>
                      )
                  )
                )}
              </td>
            </tr>
            <tr>
              <td className="key">
                검사구분<span>*</span>
              </td>
              <td>
                {mainSeq ? (
                  <div>
                    {
                      inspectionType.find(
                        (type) =>
                          type.detail_code === questionManage["inspection_type"]
                      ).code_name
                    }
                  </div>
                ) : (
                  <select
                    id="inspection_type"
                    value={questionManage["inspection_type"]}
                    onChange={(e) =>
                      setQuestionManage((prev) => ({
                        ...prev,
                        inspection_type: e.target.value,
                      }))
                    }
                  >
                    {inspectionType.map((inspection) => (
                      <option
                        key={inspection.code_name}
                        value={inspection.detail_code}
                      >
                        {inspection.code_name}
                      </option>
                    ))}
                  </select>
                )}
              </td>
              <td style={{ width: "20%" }} className="key">
                문제유형 구분<span>*</span>
              </td>
              <td>
                {mainSeq ? (
                  <div>
                    {
                      questionCategory.find(
                        (category) =>
                          category.detail_code ===
                          questionManage["question_category"]
                      ).code_name
                    }
                  </div>
                ) : (
                  <select
                    id="question_category"
                    value={questionManage["question_category"]}
                    onChange={(e) =>
                      setQuestionManage((prev) => ({
                        ...prev,
                        question_category: e.target.value,
                      }))
                    }
                  >
                    {questionCategory.map((category) => (
                      <option
                        key={category.code_name}
                        value={category.detail_code}
                      >
                        {category.code_name}
                      </option>
                    ))}
                  </select>
                )}
              </td>
            </tr>
            <tr>
              <td className="key">문제유형</td>
              <td colSpan="3">
                {mainSeq ? (
                  questionManage["question_type"] ? (
                    <div>
                      {
                        questionType.find(
                          (type) =>
                            type.detail_code === questionManage["question_type"]
                        ).code_name
                      }
                    </div>
                  ) : (
                    <div>다중등록</div>
                  )
                ) : (
                  questionType.map((type) => (
                    <React.Fragment key={type.code_name}>
                      <input
                        name="type"
                        type="radio"
                        id={type.code_name}
                        value={type.detail_code}
                        checked={
                          questionManage["question_type"] === type.detail_code
                        }
                        onChange={(e) => {
                          setQuestionManage((prev) => ({
                            ...prev,
                            question_type: e.target.value,
                          }));
                        }}
                        disabled={
                          questionManage["question_category"] === "PS01"
                        }
                      />
                      <label htmlFor={type.code_name}>{type.code_name}</label>
                    </React.Fragment>
                  ))
                )}
              </td>
            </tr>
            <tr>
              <td className="key">문제은행 소개</td>
              <td colSpan="3">
                {mainSeq ? (
                  questionManage.question_info
                ) : (
                  <textarea
                    maxLength={150}
                    value={questionManage.question_info}
                    onChange={(e) =>
                      setQuestionManage((prev) => ({
                        ...prev,
                        question_info: e.target.value,
                      }))
                    }
                  ></textarea>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </QuestionManage>
  );
}

export default QuestionBankContent;
