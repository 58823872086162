// 설문관리 - 문제은행 모달 리스트

//* style
import { CommonButton } from "@styles/common";

//* components
import QuestionBankList from "@components/counselor/QuestionBankList";

function QuestionBankModalList({
  infoSeq,
  setInfoSeq,
  questionData,
  addInfoQuestion,
  saveInfoQuestion,
  deleteInfoQuestion,
}) {
  return (
    <div className="question-list-wrap">
      <div className="question-header">
        <h2>문항 목록: {questionData.question_name}</h2>
        <div className="button-container">
          {questionData.list.length < 20 && (
            <>
              <CommonButton className="add-btn" onClick={addInfoQuestion}>
                추가
              </CommonButton>
              <CommonButton onClick={saveInfoQuestion}>저장</CommonButton>
            </>
          )}
          <CommonButton className="remove-btn" onClick={deleteInfoQuestion}>
            삭제
          </CommonButton>
        </div>
      </div>
      <div className="question-list">
        <QuestionBankList
          infoSeq={infoSeq}
          setInfoSeq={setInfoSeq}
          type="questionBankModal"
          tableData={questionData.list}
        />
      </div>
    </div>
  );
}

export default QuestionBankModalList;
