//* hooks
import moment from "moment";

export const func = {
  noticeTextHandler: (text, noticeKeywords) => {
    const noticeKeywordsList = noticeKeywords.map((data) => data.keyword);

    const keywordClassName = (part) => {
      const keyword = noticeKeywords.find((data) => data.keyword === part);

      if (keyword.severity === "주의") {
        return "caution";
      } else if (keyword.severity === "위험") {
        return "danger";
      } else {
        return "serious";
      }
    };

    if (text) {
      const noticeSentence = text
        .split(new RegExp(`(${noticeKeywordsList.join("|")})`))
        .map((part, index) => {
          if (noticeKeywordsList.includes(part)) {
            return (
              <strong className={keywordClassName(part)} key={index}>
                {part}
              </strong>
            );
          } else {
            return part;
          }
        });

      return noticeSentence;
    }
  },

  formatDate(date) {
    return moment(date).format("YYYY-MM-DD");
  },

  getNextMonthDate() {
    const currentDate = new Date();
    const nextMonthDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      currentDate.getDate()
    );
    return nextMonthDate;
  },
};
