// 상담사 - 상담 리포트 - 상세

//* hooks
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//* style
import { MainLayout, CommonButton } from "@styles/common";

//* components
import Popup from "@components/common/Popup";
import Header from "@components/common/Header";
import ConsultationReport from "@components/counselor/ConsultationReport";

function ConsultationReportDetailPage() {
  const navigate = useNavigate();
  const seq = useLocation().pathname.split("/")[2];

  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <Header />
      <MainLayout style={{ paddingBottom: "5rem" }}>
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "2rem" }}
        >
          <CommonButton onClick={() => setShowPopup(true)}>
            인쇄하기
          </CommonButton>
        </div>

        <Popup show={showPopup} onClose={() => setShowPopup(false)}>
          <ConsultationReport seq={seq} />
        </Popup>

        <ConsultationReport seq={seq} />

        <CommonButton
          type="button"
          fontSize={"1rem"}
          onClick={() => {
            navigate("/consultation-report");
          }}
          style={{
            left: "50%",
            bottom: "1rem",
            position: "absolute",
            padding: "0.6rem 2rem",
            transform: "translateX(-50%)",
          }}
        >
          목록
        </CommonButton>
      </MainLayout>
    </>
  );
}

export default ConsultationReportDetailPage;
