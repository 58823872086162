// 상담사 - 상담일지 - 상담요청전체

//* hooks
import { useEffect, useState, useMemo } from "react";

//* style
import { MainLayout } from "@styles/common";

//* api
import { api } from "@api";

//* components
import Tab from "@components/common/Tab";
import Header from "@components/common/Header";
import ConsultationList from "@components/counselor/ConsultationList";
import ConsultationSearchBar from "@components/counselor/ConsultationSearchBar";

//* func
import { func } from "@func";

function AllConsultationDiaryListPage() {
  //* state
  const [activeTab, setActiveTab] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [searchData, setSearchData] = useState({
    user_name: "",
    way: "",
    start_date: func.formatDate(new Date()),
    end_date: func.formatDate(func.getNextMonthDate()),
  });

  const tabList = ["오늘의 상담", "상담 요청 전체"];

  useEffect(() => {
    allConsultationList({ isFinish: false });
  }, []);

  const allConsultationList = async (searchData) => {
    try {
      const AllConsultationListResponse = await api.POST_CONSULTATION_LIST(
        searchData
      );

      setTableData(AllConsultationListResponse.list);
    } catch (err) {
      console.log("전체 상담 err", err);
    }
  };

  const memoizedTableData = useMemo(() => tableData, [tableData]);

  const consultationListSearch = () => {
    allConsultationList({ ...searchData, isFinish: false });
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Tab
          tabList={tabList}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ConsultationSearchBar
          searchData={searchData}
          setSearchData={setSearchData}
          consultationListSearch={consultationListSearch}
        />
        <ConsultationList tableData={memoizedTableData} />
      </MainLayout>
    </>
  );
}

export default AllConsultationDiaryListPage;
