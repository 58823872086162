// 공통 Tab

//* hooks
import { useNavigate } from "react-router-dom";

//* style
import { CommonTab } from "@styles/common";

function Tab({ tabList, activeTab, setActiveTab }) {
  const navigate = useNavigate();

  const tabNavigate = {
    "나의 상담": "/my-consultation",
    "내 정보": "/my-info",
    "오늘의 상담": "/today-consultation-diary",
    "상담 요청 전체": "/all-consultation-diary",
  };

  const tabHandler = (index, tab) => {
    setActiveTab && setActiveTab(index);
    navigate(tabNavigate[tab]);
  };

  return (
    <CommonTab>
      <ul>
        {tabList.map((tab, index) => (
          <li
            key={tab}
            onClick={() => tabHandler(index, tab)}
            className={activeTab === index ? "active" : "inactive"}
          >
            {tab}
          </li>
        ))}
      </ul>
    </CommonTab>
  );
}

export default Tab;
