// 내담자 내정보 - 나의 상담 리스트

//* hooks
import { useState, useEffect, useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";

//* api
import { api } from "@api";

//* component
import DataTable from "@components/common/DataTable";

function ConsultationList() {
  //* state
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const myConsultationList = async () => {
      try {
        const POST_CONSULTATION_LIST = await api.POST_CONSULTATION_LIST({});

        setTableData(POST_CONSULTATION_LIST.list);
      } catch (err) {
        console.log("나의 상담 err", err);
      }
    };
    myConsultationList();
  }, []);

  const memoizedTableData = useMemo(() => tableData, [tableData]);

  const columnHelper = createColumnHelper();
  const columns = useMemo(
    () => [
      columnHelper.accessor("NO", {
        header: "NO",
        size: 30,
        cell: ({ row, table }) =>
          (table
            .getSortedRowModel()
            ?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1,
      }),
      columnHelper.accessor("subject", {
        header: "상담명",
        size: 280,
      }),
      columnHelper.accessor("counsel_dt", {
        header: "상담일시",
        size: 150,
      }),
      columnHelper.accessor("counsel_way", {
        header: "상담방법",
        size: 100,
      }),
      columnHelper.accessor("status", {
        header: "상태",
        size: 100,
      }),
      columnHelper.accessor("inserted_on", {
        header: "상담신청일",
        size: 100,
        cell: ({ row }) => {
          return row.original.inserted_on.split(" ")[0];
        },
      }),
    ],
    []
  );

  return <DataTable columns={columns} tableData={memoizedTableData} />;
}

export default ConsultationList;
