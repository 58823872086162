// AUTH 네비 - 로그인 / 아이디 찾기 / 비밀번호 찾기 / 회원가입

//* hooks
import styled from "styled-components";
import { Link } from "react-router-dom";

const FindWrap = styled.ul`
  color: #888;
  display: flex;
  font-size: 0.8rem;
  margin-top: 1.2rem;
  justify-content: center;

  li {
    position: relative;
  }

  li + li {
    padding-left: 1.8rem;
  }

  li + li::before {
    top: 3px;
    width: 1px;
    content: "";
    height: 12px;
    left: 0.8rem;
    position: absolute;
    background-color: #dadada;
  }

  span {
    cursor: pointer;
  }
`;

function AuthNav({ choiceLoginType, isLogin, id }) {
  const authList = [
    { authType: "아이디 찾기", authPage: "/fdID" },
    { authType: "비밀번호 찾기", authPage: "/fdPW" },
    { authType: "회원가입", authPage: "/join" },
  ];

  return (
    <FindWrap>
      {
        // 로그인 화면이 아닌경우
        !isLogin ? (
          <>
            <li>
              <Link to="/login">로그인</Link>
            </li>
            {/* 아이디를 찾은 화면인 경우 비밀번호 찾기 추가 */}
            {id && (
              <li>
                <Link to="/fdPW">비밀번호 찾기</Link>
              </li>
            )}
          </>
        ) : (
          // 로그인 화면인 경우
          authList.map((auth, idx) => (
            <li
              key={auth.authType}
              style={{
                display:
                  idx === 2
                    ? choiceLoginType === 0
                      ? "block"
                      : "none"
                    : "block",
              }}
            >
              <Link to={auth.authPage}>{auth.authType}</Link>
            </li>
          ))
        )
      }
    </FindWrap>
  );
}

export default AuthNav;
