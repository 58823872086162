// 상담사 - 상담일지 - 오늘의상담

//* hooks
import moment from "moment";
import { useEffect, useState, useMemo } from "react";

//* style
import { MainLayout } from "@styles/common";

//* api
import { api } from "@api";

//* components
import Tab from "@components/common/Tab";
import Header from "@components/common/Header";
import ConsultationList from "@components/counselor/ConsultationList";

function TodayConsultationDiaryListPage() {
  //* state
  const [activeTab, setActiveTab] = useState(0);
  const [tableData, setTableData] = useState([]);

  const tabList = ["오늘의 상담", "상담 요청 전체"];

  useEffect(() => {
    const todayConsultationList = async () => {
      try {
        const todayConsultationListResponse = await api.POST_CONSULTATION_LIST({
          isFinish: false,
          start_date: moment(new Date()).format("YYYY-MM-DD"),
          end_date: moment(new Date()).format("YYYY-MM-DD"),
        });

        setTableData(todayConsultationListResponse.list);
      } catch (err) {
        console.log("오늘의 상담 err", err);
      }
    };
    todayConsultationList();
  }, []);

  const memoizedTableData = useMemo(() => tableData, [tableData]);

  return (
    <>
      <Header />
      <MainLayout>
        <Tab
          tabList={tabList}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ConsultationList type="today" tableData={memoizedTableData} />
      </MainLayout>
    </>
  );
}

export default TodayConsultationDiaryListPage;
