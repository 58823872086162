// 설문관리 - 진단검사 모달

//* hooks
import React from "react";
import { useState, useEffect } from "react";

//* style
import { Modal, CommonButton } from "@styles/common";

//* api
import { api } from "@api";

//* components
import QuestionBankPoolModalList from "@components/counselor/QuestionBankPoolModalList";
import QuestionDiagnosisModalList from "@components/counselor/QuestionDiagnosisModalList";

function QuestionDiagnosisModal({
  diagnosisData,
  inspectionType,
  setIsModalOpen,
  questionInfoList,
  setQuestionInfoList,
}) {
  const [isSave, setIsSave] = useState(false); // 저장 버튼 클릭 여부
  const [mainSeq, setMainSeq] = useState(null);
  const [deleteSeqList, setDeleteSeqList] = useState(null);
  const [questionBankPoolData, setQuestionBankPoolData] = useState([]);
  const [questionDiagnosisData, setQuestionDiagnosisData] = useState([]);

  useEffect(() => {
    getQuestionBankList(diagnosisData.inspection_type);

    if (questionInfoList.length) {
      setQuestionDiagnosisData(questionInfoList);
    }
  }, []);

  useEffect(() => {
    if (mainSeq) {
      api
        .GET_INFO_QUESTION_BANK_LIST(mainSeq)
        .then((res) => {
          setQuestionDiagnosisData((prev) => [...prev, ...res.list]);
        })
        .catch((err) => {
          console.log("get info 에러", err);
        });
    }
  }, [mainSeq]);

  useEffect(() => {
    if (Array.isArray(deleteSeqList) && deleteSeqList.length) {
      const deleteData = questionDiagnosisData.filter(
        (row) => !deleteSeqList.includes(row.info_seq)
      );
      setQuestionDiagnosisData(deleteData);
    }
  }, [deleteSeqList]);

  const getQuestionBankList = (type) => {
    api
      .POST_MAIN_QUESTION_BANK_LIST({
        inspection_type: type,
      })
      .then((res) => setQuestionBankPoolData(res.list))
      .catch((err) => {
        console.log("문제은행 리스트 에러", err);
      });
  };

  // 출제문항 저장(state 저장)
  const saveQuestionList = () => {
    setIsSave(true);
    setQuestionInfoList(questionDiagnosisData);
  };

  // 모달닫기 & 데이터 초기화
  const closeModal = () => {
    if (!isSave) {
      if (window.confirm("출제 문항을 저장하지 않으시겠습니까?")) {
        setIsModalOpen(false);
      }
    } else {
      setIsModalOpen(false);
    }
  };

  return (
    <Modal onClick={closeModal}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h1>문항 상세</h1>
          <button className="modal-close" onClick={closeModal}>
            X
          </button>
        </div>
        <div className="modal-content">
          <QuestionBankPoolModalList
            setMainSeq={setMainSeq}
            inspectionType={inspectionType}
            tableData={questionBankPoolData}
            getQuestionBankList={getQuestionBankList}
          />
          <div className="question-content-wrap">
            <div className="question-header">
              <h2>출제 문항</h2>
              <div className="button-container">
                <CommonButton onClick={saveQuestionList}>저장</CommonButton>
                <CommonButton
                  className="remove-btn"
                  onClick={() => setDeleteSeqList("getList")}
                >
                  선택 삭제
                </CommonButton>
              </div>
            </div>
            <div className="question-content">
              <QuestionDiagnosisModalList
                type="questionDiagnosisModal"
                deleteSeqList={deleteSeqList}
                tableData={questionDiagnosisData}
                setDeleteSeqList={setDeleteSeqList}
                setQuestionDiagnosisData={setQuestionDiagnosisData}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default QuestionDiagnosisModal;
