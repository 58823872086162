// 상담사 & 내담자 온라인 화상 채팅

//* hooks
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//* style
import { MainLayout, CommonButton } from "@styles/common";

//* api
import { api } from "@api";

//* components
import Header from "@components/common/Header";
import ConsultationArea from "@components/common/ConsultationArea";

//* pages
import ConsultationDetailPage from "@pages/client/ConsultationDetailPage";
import CounselorConsultationDetailPage from "@pages/counselor/ConsultationDetailPage";

function OnlineConsultationPage() {
  //* state
  const [isSave, setIsSave] = useState(false);
  const [consultationStatus, setConsultationStatus] = useState("");
  const [isConsultationArea, setIsConsultationArea] = useState(false);
  const [consultationDetailData, setConsultationDetailData] = useState({});

  const [cvChart, setCvChart] = useState([
    {
      id: "기쁨",
      data: [{ x: -1, y: "기쁨" }],
    },
    {
      id: "당황",
      data: [{ x: -1, y: "당황" }],
    },
    {
      id: "분노",
      data: [{ x: -1, y: "분노" }],
    },
    {
      id: "불안",
      data: [{ x: -1, y: "불안" }],
    },
    {
      id: "상처",
      data: [{ x: -1, y: "상처" }],
    },
    {
      id: "슬픔",
      data: [{ x: -1, y: "슬픔" }],
    },
    {
      id: "중립",
      data: [{ x: -1, y: "중립" }],
    },
  ]);
  const [severityChart, setSeverityChart] = useState([
    {
      id: "심각",
      data: [{ x: -1, y: "심각" }],
    },
    {
      id: "위험",
      data: [{ x: -1, y: "위험" }],
    },
    {
      id: "주의",
      data: [{ x: -1, y: "주의" }],
    },
  ]);

  const navigate = useNavigate();
  const seq = useLocation().pathname.split("/")[2];
  const isCounselor = localStorage.getItem("user_type") === "상담사";

  useEffect(() => {
    consultationDetail();
  }, []);

  const consultationDetail = async () => {
    const consultationDetailRes = await api.GET_CONSULTATION_DETAIL_DATA(seq);

    const status = consultationDetailRes.status;

    const isProgress = status === "상담중";
    const isWaiting = status === "상담대기";
    const isOnline = consultationDetailRes.counsel_way === "온라인";

    if (!isCounselor) {
      if (isOnline) {
        if (isWaiting || isProgress) {
          setIsConsultationArea(true);
        } else {
          setIsConsultationArea(false);
        }
      }
    } else {
      status === "상담완료"
        ? setIsConsultationArea(false)
        : setIsConsultationArea(true);
    }

    setConsultationDetailData(consultationDetailRes);
    setConsultationStatus(consultationDetailRes.status);
  };

  const useNavigateToList = () => {
    let path = "";

    if (!isCounselor) {
      path = "/my-consultation";
    } else {
      path =
        consultationStatus === "상담완료"
          ? "/consultation-history"
          : "/today-consultation-diary";
    }

    if (consultationStatus === "온라인" && consultationStatus === "상담중") {
      if (
        window.confirm(
          "상담중입니다. 그래도 종료하시고 목록 화면으로 이동하시겠습니까?"
        )
      ) {
        navigate(path);
      }
    } else {
      navigate(path);
    }
  };

  return (
    <>
      <Header />
      <MainLayout style={{ paddingBottom: "5rem" }}>
        {isCounselor ? (
          <CounselorConsultationDetailPage
            seq={seq}
            setIsSave={setIsSave}
            cvChart={cvChart}
            severityChart={severityChart}
            consultationDetailData={consultationDetailData}
          />
        ) : (
          <ConsultationDetailPage
            seq={seq}
            consultationDetailData={consultationDetailData}
          />
        )}

        {isConsultationArea && (
          <ConsultationArea
            seq={seq}
            isSave={isSave}
            cvChart={cvChart}
            setCvChart={setCvChart}
            severityChart={severityChart}
            setSeverityChart={setSeverityChart}
            consultationDetailData={consultationDetailData}
          />
        )}

        {!isCounselor && (
          <div id="listButton">
            <CommonButton
              type="button"
              fontSize={"1rem"}
              onClick={useNavigateToList}
              style={{ padding: "0.6rem 2rem" }}
            >
              목록
            </CommonButton>
          </div>
        )}
      </MainLayout>
    </>
  );
}

export default OnlineConsultationPage;
