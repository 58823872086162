// 회원가입 약관 동의

//* hooks
import { useState } from "react";
import styled from "styled-components";

// * style
import { CommonButton } from "@styles/common";

const TermsAgreeContainer = styled.div`
  width: 460px;

  #termsAllButton {
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    border-radius: 0.4rem;
  }

  .agree {
    color: #fff;
    background-color: rgba(7, 64, 228, 0.7);
  }

  .disagree {
    color: #666;
    background-color: #ddd;
  }

  .term {
    margin-bottom: 0.6rem;

    label {
      margin: 0rem 0.4rem;
      display: flex;
      justify-content: space-between;

      input {
        width: 20px;
      }

      span {
        font-weight: 600;
        color: ${({ theme }) => theme.fontColor.text};
      }

      img {
        cursor: pointer;
      }
    }
  }
`;

function TermsAgree({ setIsAgree }) {
  const [selectAll, setSelectAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState([false, false]);

  const termsList = [
    "서비스 이용약관 (필수)",
    "개인정보 수집 및 이용 동의 (필수)",
  ];

  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setCheckboxes(checkboxes.map(() => newSelectAll));
  };

  const handleCheckboxChange = (index) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index] = !newCheckboxes[index];
    setCheckboxes(newCheckboxes);

    if (newCheckboxes.every((checked) => checked)) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  return (
    <TermsAgreeContainer>
      <h2>
        AI 기반 정신건강 상담 시스템 회원가입을 시작합니다. <br />
        약관에 동의해 주시기 바랍니다.
      </h2>

      <button
        type="button"
        id="termsAllButton"
        className={selectAll ? "agree" : "disagree"}
        onClick={handleSelectAllChange}
      >
        약관 전체동의
      </button>

      {checkboxes.map((checked, idx) => (
        <div className="term" key={termsList[idx]}>
          <label htmlFor={termsList[idx]}>
            <div>
              <input
                type="checkbox"
                checked={checked}
                id={termsList[idx]}
                name={termsList[idx]}
                onChange={() => handleCheckboxChange(idx)}
              />
              <span>{termsList[idx]}</span>
            </div>
            <img src="/images/right_arrow.svg" />
          </label>
        </div>
      ))}

      <CommonButton
        type="button"
        width={"100%"}
        fontSize={"1.2rem"}
        disabled={!selectAll}
        onClick={() => setIsAgree(true)}
        style={{ padding: "1rem", marginTop: "2rem" }}
      >
        다음
      </CommonButton>
    </TermsAgreeContainer>
  );
}

export default TermsAgree;
