import { ResponsiveScatterPlot } from "@nivo/scatterplot";

function ScatterplotChart({ type, data }) {
  const cvColor = {
    중립: "#1f77b4",
    슬픔: "#ff7f0e",
    상처: "#2ca02c",
    불안: "#d62728",
    분노: "#9467bd",
    당황: "#8c564b",
    기쁨: "#e377c2",
  };

  const severityColor = {
    주의: "#FFD700",
    위험: "#FFA500",
    심각: "#FF0000",
  };

  return (
    <div style={{ width: "100%", height: "500px" }}>
      <ResponsiveScatterPlot
        data={data}
        // xFormat=">-.2f"
        // yFormat=">-.2f"
        xScale={{ type: "linear", min: 0, max: 90 }}
        margin={{ top: 10, right: 10, bottom: 50, left: 40 }}
        yScale={{
          type: "point",
          padding: 0.5,
        }}
        // blendMode="multiply"
        // axisTop={null}
        // axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 46,
          legendPosition: "middle",
          tickValues: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90],
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 10,
          tickRotation: 0,
          legendOffset: -60,
          legendPosition: "middle",
        }}
        colors={(node) =>
          type === "cv" ? cvColor[node.serieId] : severityColor[node.serieId]
        }
      />
    </div>
  );
}

export default ScatterplotChart;
