// 설문관리 - 진단검사 관리 모달 - 출제문항 리스트

//* hooks
import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "@components/common/DataTable";

function QuestionDiagnosisModalList({
  type,
  tableData,
  deleteSeqList,
  setDeleteSeqList,
  setQuestionDiagnosisData,
}) {
  const columnHelper = createColumnHelper();

  const columns = useMemo(() => {
    const baseColumns = [
      {
        id: "select",
        header: ({ table }) => (
          <input
            type="checkbox"
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected() ? "true" : undefined}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <input
              type="checkbox"
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected() ? "true" : undefined}
              onChange={row.getToggleSelectedHandler()}
            />
          </div>
        ),
        size: 50,
      },
      columnHelper.accessor("문제번호", {
        header: "문제번호",
        size: 10,
        cell: ({ row }) => <p>{row.index + 1}</p>,
      }),
      columnHelper.accessor("question", {
        header: "문제",
        size: 200,
      }),
      columnHelper.accessor("question_form_name", {
        header: "문제형식",
        size: 80,
      }),
      columnHelper.accessor("choice_count", {
        header: "보기수",
        size: 80,
      }),
      columnHelper.accessor("points", {
        header: "배점",
        size: 80,
      }),
    ];

    return baseColumns;
  }, [type]);

  return (
    <DataTable
      type={type}
      columns={columns}
      tableData={tableData}
      deleteSeqList={deleteSeqList}
      setDeleteSeqList={setDeleteSeqList}
      setQuestionDiagnosisData={setQuestionDiagnosisData}
    />
  );
}

export default QuestionDiagnosisModalList;
