// 비밀번호 찾기 - 회원 정보 입력

//* hooks
import * as Yup from "yup";
import { useFormik } from "formik";

//* style
import {
  CommonButton,
  FindIdPwContainer,
  CommonRequiedMessage,
} from "@styles/common";

//* api
import { api } from "@api";

function FindPWForm({ setId }) {
  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: {
      userid: "",
      cellphone: "",
    },
    validationSchema: Yup.object().shape({
      userid: Yup.string().required("아이디를 입력해 주세요."),
      cellphone: Yup.string().required("휴대전화번호를 입력해 주세요."),
    }),
    onSubmit: async (values) => {
      try {
        const findPWResponse = await api.POST_FIND_PW(values);
        setId(findPWResponse.userid);
      } catch (err) {
        console.log("비밀번호 찾기 err", err);
        alert(err.response.data.message);
      }
    },
  });

  return (
    <FindIdPwContainer>
      <h2>비밀번호를 찾고자 하는 아이디와 휴대전화 번호를 입력해주세요.</h2>

      <form onSubmit={handleSubmit}>
        <div id="findInput">
          <label htmlFor="userid">
            <span>아이디</span>
            <input
              id="userid"
              type="text"
              name="userid"
              value={values.userid}
              onChange={handleChange}
            />
          </label>
          {touched.userid && errors.userid && (
            <CommonRequiedMessage>{errors.userid}</CommonRequiedMessage>
          )}

          <label htmlFor="cellphone">
            <span>휴대전화</span>
            <input
              type="tel"
              id="cellphone"
              name="cellphone"
              value={values.cellphone}
              onChange={handleChange}
            />
          </label>
          {touched.cellphone && errors.cellphone && (
            <CommonRequiedMessage>{errors.cellphone}</CommonRequiedMessage>
          )}
        </div>

        <CommonButton type="submit" width={"100%"} fontSize={"1.2rem"}>
          다음
        </CommonButton>
      </form>
    </FindIdPwContainer>
  );
}

export default FindPWForm;
