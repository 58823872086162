// 내담자 내정보 - 나의 상담 탭 리스트

//* hooks
import { useState } from "react";

//* style
import { MainLayout } from "@styles/common";

//* components
import Tab from "@components/common/Tab";
import Header from "@components/common/Header";
import ConsultationList from "@components/client/myInfo/ConsultationList";

function ConsultationListPage() {
  //* state
  const [activeTab, setActiveTab] = useState(0);

  const tabList = ["나의 상담", "내 정보"];

  return (
    <>
      <Header />
      <MainLayout>
        <Tab
          tabList={tabList}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ConsultationList />
      </MainLayout>
    </>
  );
}

export default ConsultationListPage;
