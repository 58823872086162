// 공통 시간 선택 UI

//* hooks
import "moment/locale/ko";
import moment from "moment";
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";

//* style
import { HourChip } from "@styles/common";

//* api
import { api } from "@api";

function Hour({
  hour,
  setHour,
  pickDate,
  selectAmHourIdx,
  selectPmHourIdx,
  setSelectAmHourIdx,
  setSelectPmHourIdx,
}) {
  //* state
  const [disabledHour, setDisabledHour] = useState([]);
  const [reservationDate, setReservationDate] = useState("");

  //* ref
  const isInitialMount = useRef(true);

  useEffect(() => {
    const reservationDate = moment(pickDate).locale("ko").format("MM.DD (ddd)");
    setReservationDate(reservationDate);

    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      let date = pickDate ? pickDate : new Date();
      consultationHourRequest(moment(date).format("YYYY-MM-DD"));
    }

    setHour({ hour: "", type: "" });
  }, [pickDate]);

  const amHour = ["09", "10", "11"];
  const pmHour = ["01", "02", "03", "04", "05"];

  const hourHandler = (type, idx, hour) => {
    if (!disabledHour.includes(hour)) {
      setSelectAmHourIdx(type === "am" ? idx : -1);
      setSelectPmHourIdx(type === "am" ? -1 : idx);

      setHour({ hour, type });
    }
  };

  const consultationHourRequest = async (date) => {
    try {
      const consultationHourResponse = await api.GET_CONSULTATION_HOUR(date);

      const hourFormat = consultationHourResponse.cnsltTime.map((hour) => {
        if (hour > 12) {
          return "0" + String(hour - 12);
        } else {
          return String(hour);
        }
      });
      setDisabledHour(hourFormat);
      setSelectAmHourIdx(-1);
      setSelectPmHourIdx(-1);
    } catch (err) {
      console.log("상담시간 err", err);
    }
  };

  return (
    <HourContainer>
      <p className="reservation-date">
        예약 일자 : {reservationDate}{" "}
        {hour.type === ""
          ? ""
          : `${hour.type === "am" ? "오전" : "오후"} ${hour.hour}:00`}
      </p>
      <p>오전</p>
      <div className="reservation-hour">
        {amHour.map((hour, idx) => (
          <HourChip key={idx} onClick={() => hourHandler("am", idx, hour)}>
            <div
              className={`${selectAmHourIdx === idx ? "active" : ""} ${
                disabledHour.includes(hour) ? "disabled" : ""
              }`}
            >
              {hour}:00
            </div>
          </HourChip>
        ))}
      </div>

      <p>오후</p>
      <div className="reservation-hour">
        {pmHour.map((hour, idx) => (
          <HourChip key={idx} onClick={() => hourHandler("pm", idx, hour)}>
            <div
              className={`${selectPmHourIdx === idx ? "active" : ""} ${
                disabledHour.includes(hour) ? "disabled" : ""
              }`}
            >
              {hour}:00
            </div>
          </HourChip>
        ))}
      </div>
    </HourContainer>
  );
}

const HourContainer = styled.div`
  .reservation-date {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .reservation-hour {
    display: flex;
    flex-wrap: wrap;
  }
`;
export default Hour;
