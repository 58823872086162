import theme from "@styles/theme";
import Router from "@router/Router";
import { BrowserRouter } from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components";

const styled = { createGlobalStyle };

//* 전역 스타일
const GlobalStyles = styled.createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  ul,
  ol,
  li {
    list-style: none;
  }
  input,
  textarea {
    outline: none;
    padding: 0.6rem;
    border-radius: 0.4rem;
    border: 1px solid ${({ theme }) => theme.color.border};
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  button {
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 0.4rem;
  }
  select {
    padding: 0.6rem;
    border-radius: 0.4rem;
    border: 1px solid ${({ theme }) => theme.color.border};
  }

  .required {
    color: ${({ theme }) => theme.color.error};
  }
`;

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Router />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
