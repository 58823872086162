// 아이디 찾기 - 회원 정보 입력

//* hooks
import * as Yup from "yup";
import { useFormik } from "formik";

//* style
import {
  CommonButton,
  FindIdPwContainer,
  CommonRequiedMessage,
} from "@styles/common";

//* api
import { api } from "@api";

function FindId({ setId }) {
  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: {
      username: "",
      cellphone: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("이름을 입력해 주세요."),
      cellphone: Yup.string().required("휴대전화번호를 입력해 주세요."),
    }),
    onSubmit: async (values) => {
      try {
        const findIDResponse = await api.POST_FIND_ID(values);
        setId(findIDResponse.userid);
      } catch (err) {
        console.log("아이디 찾기 err", err);
        alert(err.response.data.message);
      }
    },
  });

  return (
    <FindIdPwContainer>
      <h2>
        회원정보에 등록한 이름 및 휴대전화 번호가 같아야 아이디를 찾을 수
        있습니다.
      </h2>

      <form onSubmit={handleSubmit}>
        <div id="findInput">
          <label htmlFor="username">
            <span>이름</span>
            <input
              type="text"
              id="username"
              name="username"
              value={values.username}
              onChange={handleChange}
            />
          </label>
          {touched.username && errors.username && (
            <CommonRequiedMessage>{errors.username}</CommonRequiedMessage>
          )}

          <label htmlFor="cellphone">
            <span>휴대전화</span>
            <input
              type="tel"
              id="cellphone"
              name="cellphone"
              value={values.cellphone}
              onChange={handleChange}
            />
          </label>
          {touched.cellphone && errors.cellphone && (
            <CommonRequiedMessage>{errors.cellphone}</CommonRequiedMessage>
          )}
        </div>

        <CommonButton type="submit" width={"100%"} fontSize={"1.2rem"}>
          다음
        </CommonButton>
      </form>
    </FindIdPwContainer>
  );
}

export default FindId;
