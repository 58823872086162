// 회원가입 페이지

//* hooks
import { useState } from "react";

// * style
import { AuthContainer } from "@styles/common";

//* component
import AuthNav from "@components/auth/AuthNav";
import JoinForm from "@components/auth/JoinForm";
import TermsAgree from "@components/auth/TermsAgree";

function Join() {
  const [isAgree, setIsAgree] = useState(false);

  const initialValues = {
    userid: "",
    username: "",
    userpass: "",
    userpassCheck: "",
    cellphone: "",
    email: "",
    year: "",
    month: "",
    date: "",
    usertype: "내담자",
  };

  return (
    <AuthContainer>
      <h1>회원가입</h1>
      {isAgree ? (
        <JoinForm initialValues={initialValues} />
      ) : (
        <TermsAgree setIsAgree={setIsAgree} />
      )}
      <AuthNav />
    </AuthContainer>
  );
}

export default Join;
