// 설문관리 - 진단검사 출제 - 진단검사 관리

//* hooks
import React from "react";

//* style
import { QuestionManage, CommonButton } from "@styles/common";

//* api
import { api } from "@api";

function QuestionDiagnosisContent({
  useType,
  mainSeq,
  diagnosisData,
  setIsModalOpen,
  inspectionType,
  addMainQuestion,
  saveMainQuestion,
  setDiagnosisData,
  questionInfoList,
  deleteMainQuestion,
}) {
  const openDiagnosisModal = () => {
    setIsModalOpen(true);
  };

  const deleteInfoSeq = () => {
    if (window.confirm("검사 문항을 삭제하시겠습니까?")) {
      api
        .DELETE_DIAGNOSIS_INFO(mainSeq)
        .then((res) => {
          if (res.status === 200) {
            setDiagnosisData({ ...diagnosisData, diagnosis_info_count: 0 });
          }
        })
        .catch((err) => console.log("출제문항 삭제 err", err));
    }
  };

  return (
    <QuestionManage>
      <div id="questionManageHeader">
        <div className="title">
          <h2>진단검사 관리</h2>
          <ul>
            <li>
              <span>*</span>표시는 필수 입력 항목 입니다.
            </li>
          </ul>
        </div>
        <div className="btn-wrap">
          <CommonButton onClick={addMainQuestion}>추가</CommonButton>
          <CommonButton onClick={saveMainQuestion}>저장</CommonButton>
          <CommonButton onClick={deleteMainQuestion}>삭제</CommonButton>
        </div>
      </div>
      <div id="questionManageContent">
        <table>
          <thead></thead>
          <tbody>
            <tr>
              <td style={{ width: "20%" }} className="key">
                진단검사 명<span>*</span>
              </td>
              <td colSpan="3">
                {mainSeq ? (
                  <div>{diagnosisData.diagnosis_name}</div>
                ) : (
                  <input
                    type="text"
                    maxLength={20}
                    value={diagnosisData.diagnosis_name}
                    onChange={(e) =>
                      setDiagnosisData((prev) => ({
                        ...prev,
                        diagnosis_name: e.target.value,
                      }))
                    }
                  />
                )}
              </td>
            </tr>
            <tr>
              <td className="key">
                사용여부<span>*</span>
              </td>
              <td colSpan="3">
                {mainSeq ? (
                  <div>{diagnosisData.use_type_name}</div>
                ) : (
                  useType.map(
                    (yn) =>
                      yn.code_name !== "전체" && (
                        <React.Fragment key={yn.code_name}>
                          <input
                            name="yn"
                            type="radio"
                            id={yn.code_name}
                            value={yn.detail_code}
                            checked={diagnosisData["use_yn"] === yn.detail_code}
                            onChange={(e) => {
                              setDiagnosisData((prev) => ({
                                ...prev,
                                use_yn: e.target.value,
                              }));
                            }}
                          />
                          <label htmlFor={yn.code_name}>{yn.code_name}</label>
                        </React.Fragment>
                      )
                  )
                )}
              </td>
            </tr>
            <tr>
              <td className="key">
                검사구분<span>*</span>
              </td>
              <td>
                {mainSeq ? (
                  <div>{diagnosisData.inspection_type_name}</div>
                ) : (
                  <select
                    id="inspection_type"
                    value={diagnosisData["inspection_type"]}
                    onChange={(e) =>
                      setDiagnosisData((prev) => ({
                        ...prev,
                        inspection_type: e.target.value,
                      }))
                    }
                  >
                    {inspectionType.map((inspection) => (
                      <option
                        key={inspection.code_name}
                        value={inspection.detail_code}
                      >
                        {inspection.code_name}
                      </option>
                    ))}
                  </select>
                )}
              </td>
              <td style={{ width: "20%" }} className="key">
                검사시기
              </td>
              <td>
                {mainSeq ? (
                  <div>{diagnosisData.inspection_time_name}</div>
                ) : (
                  [
                    { inspection_time: "I00", key: "상담신청" },
                    { inspection_time: "I01", key: "온라인상담" },
                  ].map((time) => (
                    <React.Fragment key={time.inspection_time}>
                      <input
                        type="radio"
                        name="inspection_time"
                        id={time.inspection_time}
                        value={time.inspection_time}
                        checked={
                          diagnosisData["inspection_time"] ===
                          time.inspection_time
                        }
                        onChange={(e) => {
                          setDiagnosisData((prev) => ({
                            ...prev,
                            inspection_time: e.target.value,
                          }));
                        }}
                      />
                      <label htmlFor={time.inspection_time}>{time.key}</label>
                    </React.Fragment>
                  ))
                )}
              </td>
            </tr>
            <tr>
              <td className="key">검사대상</td>
              <td colSpan="3">
                {mainSeq ? (
                  <div>{diagnosisData.inspection_target}</div>
                ) : (
                  <div className="inspection_target">
                    <input
                      type="text"
                      value={diagnosisData.inspection_target}
                      onChange={(e) =>
                        setDiagnosisData((prev) => ({
                          ...prev,
                          inspection_target: e.target.value,
                        }))
                      }
                    />
                    <CommonButton>검색</CommonButton>
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td className="key">검사문항 만들기</td>
              <td colSpan="3">
                <div className="make-inspection">
                  {!mainSeq && (
                    <CommonButton onClick={openDiagnosisModal}>
                      문항만들기
                    </CommonButton>
                  )}
                  <p>
                    문항 수:{" "}
                    {mainSeq
                      ? diagnosisData.diagnosis_info_count
                      : questionInfoList.length}
                  </p>
                  {diagnosisData.diagnosis_info_count > 1 && (
                    <CommonButton className="remove" onClick={deleteInfoSeq}>
                      삭제
                    </CommonButton>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </QuestionManage>
  );
}

export default QuestionDiagnosisContent;
