// 알림 키워드 페이지

//* hooks
import styled from "styled-components";
import { useState, useEffect, useMemo } from "react";

//* style
import { MainLayout, CommonButton } from "@styles/common";

//* api
import { api } from "@api";

//* components
import Tab from "@components/common/Tab";
import Header from "@components/common/Header";
import NotificationKeywordList from "@components/counselor/NotificationKeywordList";

function NotificationKeywordPage() {
  //* state
  const [tableData, setTableData] = useState([]);
  const [keyword, setKeyword] = useState({
    add: "",
    search: "",
    severity: "주의",
  });

  const tabList = ["키워드 목록"];
  const severityList = ["주의", "위험", "심각"];
  const memoizedTableData = useMemo(() => tableData, [tableData]);

  useEffect(() => {
    noticeKeywordsRequest({});
  }, []);

  const noticeKeywordsRequest = async (keyword) => {
    try {
      const noticeKeywordsResponse = await api.POST_NOTICE_KEYWORDS_LIST(
        keyword
      );

      setKeyword({ add: "", search: "", severity: "주의" });
      setTableData(noticeKeywordsResponse.list);
    } catch (err) {
      console.log("알림 키워드 리스트 err", err);
      alert("알림 키워드 리스트 err", err);
    }
  };

  const addKeyword = async () => {
    try {
      const addKeywordResponse = await api.POST_NOTICE_KEYWORDS({
        keyword: keyword.add,
        severity: keyword.severity,
      });

      if (addKeywordResponse.status === 201) {
        noticeKeywordsRequest({});
      }
    } catch (err) {
      console.log("알림 키워드 등록 err", err);
      alert("알림 키워드 등록 err", err);
    }
  };

  const searchKeyword = () => {
    noticeKeywordsRequest({ keyword: keyword.search });
  };

  const deleteKeyword = async (seq) => {
    if (window.confirm("선택 하신 키워드를 삭제 하시겠습니까?")) {
      try {
        const deleteKeywordResponse = await api.DELETE_NOTICE_KEYWORDS({
          seq,
        });
        if (deleteKeywordResponse.status === 200) {
          noticeKeywordsRequest({});
          alert("삭제 되었습니다.");
        }
      } catch (err) {
        console.log("알림 키워드 삭제 err", err);
        alert("알림 키워드 삭제 err", err);
      }
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <h2 id="applicationTitle">알림 키워드</h2>
        <NotificationContainer>
          <div id="notificationWrap">
            <div className="notification-form">
              <div id="severityRadioContainer">
                <p>* 키워드 심각도: </p>
                <div id="severityWrap">
                  {severityList.map((severity) => (
                    <div id="severity" key={severity}>
                      <input
                        type="radio"
                        id={severity}
                        name="severity"
                        value={severity}
                        onChange={(e) =>
                          setKeyword((prevKeyword) => ({
                            ...prevKeyword,
                            severity: e.target.value,
                          }))
                        }
                        checked={keyword.severity === severity}
                      />
                      <label htmlFor={severity}>{severity}</label>
                    </div>
                  ))}
                </div>
              </div>
              <div id="severityInputContainer">
                <input
                  type="text"
                  value={keyword.add}
                  onChange={(e) =>
                    setKeyword((prevKeyword) => ({
                      ...prevKeyword,
                      add: e.target.value,
                    }))
                  }
                  placeholder="키워드를 입력해 주세요"
                />
                <CommonButton width={"10%"} onClick={addKeyword}>
                  등록
                </CommonButton>
              </div>
            </div>

            <Tab tabList={tabList} activeTab={0} />

            <div className="notification-form">
              <input
                type="text"
                value={keyword.search}
                onChange={(e) =>
                  setKeyword((prevKeyword) => ({
                    ...prevKeyword,
                    search: e.target.value,
                  }))
                }
                placeholder="검색할 키워드를 입력해 주세요"
              />
              <CommonButton width={"10%"} onClick={searchKeyword}>
                검색
              </CommonButton>
            </div>

            <NotificationKeywordList
              tableData={memoizedTableData}
              deleteKeyword={deleteKeyword}
            />
          </div>
        </NotificationContainer>
      </MainLayout>
    </>
  );
}

const NotificationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  flex-direction: column;
  justify-content: center;

  #notificationWrap {
    width: 80%;
  }

  .notification-form {
    display: flex;
    margin: 1rem 0rem;
    justify-content: space-between;

    input {
      width: 88%;
    }

    #severityRadioContainer {
      width: 34%;
      display: flex;
      align-items: center;

      #severityWrap {
        display: flex;

        #severity {
          display: flex;
          margin: 0 0.6rem;

          input {
            width: auto;
            margin-right: 0.2rem;
          }
        }
      }
    }

    #severityInputContainer {
      width: 66%;
      display: flex;
      justify-content: space-between;

      input {
        width: 88%;
      }
    }
  }
`;

export default NotificationKeywordPage;
