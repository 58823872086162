// 공통 레이더 차트

import { ResponsiveRadar } from "@nivo/radar";

function RadarChart({ data, type }) {
  return (
    <ResponsiveRadar
      height={400}
      data={data}
      keys={
        type === "multi"
          ? ["안면", "음성"]
          : type === "cv"
          ? ["안면"]
          : ["음성"]
      }
      indexBy="emotion"
      gridLabelOffset={20}
      dotSize={3}
      dotBorderWidth={2}
      blendMode="multiply"
      margin={{ top: 40, right: 50, bottom: 60, left: 64 }}
      colors={
        type == "multi"
          ? { scheme: "nivo" }
          : [type === "cv" ? "rgb(232, 193, 160)" : "#e25d47"]
      }
      legends={[
        {
          // data: [
          //   {
          //     label: `${type === "cv" ? "안면" : "음성"} 감정`,
          //     color: type === "cv" ? "rgb(232, 193, 160)" : "#e25d47",
          //   },
          // ],
          anchor: "top-left",
          direction: "column",
          translateX: -50,
          translateY: -40,
          itemWidth: 80,
          itemHeight: 20,
          itemTextColor: "#999",
          symbolSize: 12,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );
}

export default RadarChart;
