// 설문관리 - 진단검사출제 페이지

//* hooks
import { useState, useEffect } from "react";

//* style
import { MainLayout } from "@styles/common";

//* api
import { api } from "@api";

//* components
import Header from "@components/common/Header";
import QuestionSearchBar from "@components/counselor/QuestionSearchBar";
import QuestionDiagnosisList from "@components/counselor/QuestionDiagnosisList";
import QuestionDiagnosisModal from "@components/counselor/QuestionDiagnosisModal";
import QuestionDiagnosisContent from "@components/counselor/QuestionDiagnosisContent";

function QuestionDiagnosisPage() {
  const diagnosisInitData = {
    use_yn: "Y", // 사용여부
    diagnosis_name: "", // 진단검사 명
    inspection_type: "", // 검사구분
    inspection_target: "", // 검사대상
    inspection_time: "I00", // 검사시기
  };
  const [searchData, setSearchData] = useState({
    use_yn: "",
    search_value: "",
  });
  const [useType, setUseYn] = useState([]); // 사용 여부
  const [mainSeq, setMainSeq] = useState(null); // 선택된 main seq
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달
  const [diagnosisList, setDiagnosisList] = useState([]); // 리스트 데이터
  const [inspectionType, setInspectionType] = useState([]); // 검사 구분
  const [questionInfoList, setQuestionInfoList] = useState([]); // 출제문항 저장 데이터
  const [diagnosisData, setDiagnosisData] = useState(diagnosisInitData); // 진단검사 데이터

  useEffect(() => {
    diagnosisListRequest();
  }, []);

  useEffect(() => {
    if (mainSeq) {
      api.GET_DIAGNOSIS_DETAIL(mainSeq).then((res) => {
        setDiagnosisData(res.result);
      });
    }
  }, [mainSeq]);

  // 진단검사 목록 요청
  const diagnosisListRequest = async () => {
    try {
      const res = await api.POST_DIAGNOSIS_LIST(searchData);

      if (res.status === 200) {
        setUseYn(res.useTypeCodes);
        setDiagnosisList(res.list);
        setInspectionType(res.inspectionTypeCodes);
        setDiagnosisData({
          ...diagnosisInitData,
          use_yn: res.useTypeCodes[0].detail_code,
          inspection_type: res.inspectionTypeCodes[0].detail_code,
        });
      }
    } catch (err) {
      console.log("진단 리스트 err", err);
    }
  };

  // 검사 추가
  const addMainQuestion = () => {
    setMainSeq(null);
    setDiagnosisData(setQuestionInfoList);
    setDiagnosisData(diagnosisInitData);
  };

  // 검사 저장
  const saveMainQuestion = async () => {
    if (!diagnosisData.diagnosis_name) {
      alert("진단검사명을 입력해주세요.");
      return;
    } else {
      try {
        const infoSeq = questionInfoList.map((list) => list.info_seq);
        const requestData = { ...diagnosisData, info_seq: infoSeq };
        const saveRequest = await api.POST_ADD_DIAGNOSIS(requestData);
        if (saveRequest.status === 200) {
          diagnosisListRequest();
          setQuestionInfoList([]);
        }
      } catch (err) {
        console.log("main seq 저장 err", err);
      }
    }
  };

  // 검사 삭제
  const deleteMainQuestion = () => {
    if (mainSeq === null) {
      alert("삭제할 진단검사를 선택해주세요.");
      return;
    } else {
      if (window.confirm("진단검사를 삭제하시겠습니까?")) {
        api.DELETE_DIAGNOSIS(mainSeq).then((res) => {
          if (res.status === 200) {
            setMainSeq(null);
            diagnosisListRequest();
          }
        });
      }
    }
  };

  // 리스트 검색
  const questionListSearch = () => {
    api.POST_DIAGNOSIS_LIST(searchData).then((res) => {
      setDiagnosisList(res.list);
    });
  };

  return (
    <>
      <Header />
      <MainLayout>
        <QuestionSearchBar
          useType={useType}
          searchData={searchData}
          type="questionDiagnosis"
          setSearchData={setSearchData}
          questionListSearch={questionListSearch}
        ></QuestionSearchBar>
        <QuestionDiagnosisList
          mainSeq={mainSeq}
          setMainSeq={setMainSeq}
          type="questionDiagnosis"
          tableData={diagnosisList}
        />
        <QuestionDiagnosisContent
          useType={useType}
          mainSeq={mainSeq}
          diagnosisData={diagnosisData}
          inspectionType={inspectionType}
          setIsModalOpen={setIsModalOpen}
          addMainQuestion={addMainQuestion}
          setDiagnosisData={setDiagnosisData}
          saveMainQuestion={saveMainQuestion}
          questionInfoList={questionInfoList}
          deleteMainQuestion={deleteMainQuestion}
        />
        {isModalOpen && (
          <QuestionDiagnosisModal
            diagnosisData={diagnosisData}
            inspectionType={inspectionType}
            setIsModalOpen={setIsModalOpen}
            questionInfoList={questionInfoList}
            setQuestionInfoList={setQuestionInfoList}
          />
        )}
      </MainLayout>
    </>
  );
}

export default QuestionDiagnosisPage;
