// 로그인 페이지

//* hooks
import * as Yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// * style
import {
  CommonButton,
  AuthContainer,
  CommonRequiedMessage,
} from "@styles/common";

//* api
import { api } from "@api";

//* component
import AuthNav from "@components/auth/AuthNav";

function LoginPage() {
  //* state
  const [choiceLoginType, setLoginType] = useState(0); // 상담자(0) or 상담사(1) 상태

  // 로그인 타입
  const loginType = ["상담자", "상담사"];

  const navigate = useNavigate();

  const { handleSubmit, handleChange, values, touched, errors, handleReset } =
    useFormik({
      initialValues: {
        userid: "",
        userpass: "",
      },
      validationSchema: Yup.object().shape({
        userid: Yup.string().required("아이디를 입력해 주세요."),
        userpass: Yup.string().required("비밀번호를 입력해 주세요"),
      }),
      onSubmit: async (values) => {
        // console.log({ ...values, type: choiceLoginType });

        try {
          const loginResponse = await api.POST_LOGIN(values);
          localStorage.setItem("user_name", loginResponse.user_name);
          localStorage.setItem("user_type", loginResponse.user_type);
          localStorage.setItem("access_token", loginResponse.access_token);
          loginResponse.user_type === "상담사"
            ? navigate("/today-consultation-diary")
            : navigate("/consultation-application");
        } catch (err) {
          console.log("로그인 err", err);

          if (err.response.status) {
            alert(err.response.data.message);
          }
        }
      },
    });

  // login type 변경 & input 초기화
  const changeLoginType = (index) => {
    setLoginType(index);
    handleReset();
  };

  return (
    <AuthContainer>
      <LoginContainer>
        <h1>정신건강 상담 시스템</h1>
        <LoginTypeWrap>
          {loginType.map((type, idx) => (
            <li
              key={type}
              onClick={() => changeLoginType(idx)}
              className={idx === choiceLoginType ? "tab choice-tab" : "tab"}
            >
              {type}
            </li>
          ))}
        </LoginTypeWrap>
        <LoginFormWrap onSubmit={handleSubmit}>
          <div className="id-pw-wrap">
            <div>
              <input
                id="userid"
                type="text"
                placeholder="아이디"
                value={values.userid}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                id="userpass"
                type="password"
                placeholder="비밀번호"
                value={values.userpass}
                onChange={handleChange}
              />
            </div>
          </div>

          {touched.userid && errors.userid && (
            <CommonRequiedMessage>{errors.userid}</CommonRequiedMessage>
          )}
          {!errors.userid && touched.userpass && errors.userpass && (
            <CommonRequiedMessage>{errors.userpass}</CommonRequiedMessage>
          )}

          <CommonButton
            type="submit"
            width={"100%"}
            fontSize={"1.5rem"}
            style={{ marginTop: "1rem" }}
          >
            로그인
          </CommonButton>
        </LoginFormWrap>
      </LoginContainer>
      <AuthNav choiceLoginType={choiceLoginType} isLogin={true} />
    </AuthContainer>
  );
}

const LoginContainer = styled.div`
  width: 460px;

  h1 {
    text-align: center;
    margin-bottom: 2.4rem;
    font-size: 2rem !important;
    color: ${({ theme }) => theme.color.primary};
  }
`;

const LoginTypeWrap = styled.ul`
  color: #777;
  display: flex;
  cursor: pointer;
  font-weight: bold;
  align-items: center;
  flex-direction: row;
  background-color: #f6f7f9;

  > li:first-child {
    border-right: none;
  }

  .tab {
    width: 50%;
    padding: 1rem;
    font-size: 1.1rem;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.color.border};
  }

  .choice-tab {
    background-color: #fff;
    border-bottom-color: transparent;
    color: ${({ theme }) => theme.fontColor.text};
  }
`;

const LoginFormWrap = styled.form`
  display: flex;
  text-align: center;
  position: relative;
  flex-direction: column;
  padding: 1.5rem 1.8rem;
  border: 1px solid ${({ theme }) => theme.color.border};
  border-top: none;

  .id-pw-wrap {
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.color.border};

    > div:first-child {
      border-bottom: 1px solid ${({ theme }) => theme.color.border};
    }

    div {
      padding: 1rem 1.2rem;
    }

    input {
      width: 100%;
      border: none;
      font-size: 1.2rem;
    }
  }
`;

export default LoginPage;
