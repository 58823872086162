// 아이디 찾기 - 아이디 확인

function FoundID({ id }) {
  return (
    <>
      <h2>회원정보에 등록한 정보와 일치하는 아이디 입니다.</h2>

      <p id="userID">{id}</p>
    </>
  );
}

export default FoundID;
