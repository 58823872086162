const fontSize = {
  xs: "0.5rem",
  sm: "0.75rem",
  base: "1rem",
  md: "1.25rem",
  lg: "1.5rem",
};

const color = {
  primary: "#09aa5c",
  "primary-2": "rgb(58, 202, 154)",
  secondary: "#f8faf0",
  border: "#c6c6c6",
  caution: "#FFD700",
  danger: "#FFA500",
  error: "#FF0000",
  active: "#f5a52a",
};

const fontColor = {
  text: "#333",
  "text-2": "#75675c",
  "text-3": "#21140F",
};

const theme = {
  fontSize,
  color,
  fontColor,
};

export default theme;
