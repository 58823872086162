// 공통 달력

//* hooks
import moment from "moment";
import Calendar from "react-calendar";
import { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css"; // css import

function CalendarPicker({
  setPickDate,
  choicePrevDay,
  initialDate,
  isDisabled,
}) {
  //* state

  const [value, onChange] = useState(initialDate);

  useEffect(() => {
    setPickDate(moment(value).format("YYYY-MM-DD"));
  }, [value]);

  const tileDisabled = ({ date }) => {
    return true; // 항상 true를 반환하여 모든 날짜를 비활성화합니다.
  };

  return (
    <Calendar
      value={value}
      onChange={onChange}
      minDate={choicePrevDay ? "" : new Date()}
      tileDisabled={() => isDisabled}
      // selectRange={true}
    />
  );
}

export default CalendarPicker;
