// 설문관리 - 진단검사 목록 리스트

//* hooks
import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";

//* style
import { QuestionListHeader } from "@styles/common";

//* component
import DataTable from "@components/common/DataTable";

function QuestionDiagnosisList({ type, mainSeq, setMainSeq, tableData }) {
  const columnHelper = createColumnHelper();

  const columns = useMemo(() => {
    const baseColumns = [
      columnHelper.accessor("NO", {
        header: "NO",
        size: 10,
        cell: ({ row, table }) =>
          (table
            .getSortedRowModel()
            ?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1,
      }),
      columnHelper.accessor("diagnosis_name", {
        header: "진단검사 명",
        size: 200,
      }),
      columnHelper.accessor("inspection_type_name", {
        header: "검사구분",
        size: 80,
      }),
      columnHelper.accessor("use_type_name", {
        header: "사용여부",
        size: 80,
      }),
      columnHelper.accessor("user_name", {
        header: "출제자",
        size: 80,
      }),
      columnHelper.accessor("inserted_on", {
        header: "작성일",
        size: 80,
      }),
    ];

    return baseColumns;
  }, [type]);

  return (
    <>
      <QuestionListHeader>
        <h2>진단검사 목록</h2>
      </QuestionListHeader>

      <DataTable
        type={type}
        columns={columns}
        mainSeq={mainSeq}
        tableData={tableData}
        setMainSeq={setMainSeq}
      />
    </>
  );
}

export default QuestionDiagnosisList;
