// 설문관리 - 문제은행 모달

//* hooks
import React from "react";
import { useState, useEffect } from "react";

//* style
import { Modal } from "@styles/common";

//* api
import { api } from "@api";

//* components
import QuestionBankModalList from "@components/counselor/QuestionBankModalList";

function QuestionBankModal({
  infoSeq,
  setInfoSeq,
  questionType,
  modalMainSeq,
  setModalMainSeq,
  setIsQuestionModalOpen,
}) {
  const lookList = [
    { choice_title: "", answer: "Y" },
    { choice_title: "", answer: "N" },
  ]; // 보기 리스트

  // main seq 정보
  const [questionData, setQuestionData] = useState({
    list: [],
  });
  // info seq 정보
  const [questionInfo, setQuestionInfo] = useState({
    answer: "Y", // 예/아니오일 경우 정답
    points: 10, // 배점
    question: "", // 문제
    descriptive: "", // 서술형일 경우 정답
    choice_count: 2, // 보기수
    question_form: "", // 문제 형식
  });
  const [isOneOrMulti, setIsOneOrMulti] = useState(true); // 단답, 다답일 경우
  const [isListOverLimit, setIsListOverLimit] = useState(false); // 리스트 갯수가 20개 넘는지
  const [questionAnswerList, setQuestionAnswerList] = useState(lookList); // 질문, 정답 리스트

  useEffect(() => {
    if (modalMainSeq) {
      infoQuestionRequest();
    }
  }, [modalMainSeq]);

  useEffect(() => {
    if (questionData.list.length >= 20) {
      setIsListOverLimit(true);
    } else {
      setIsListOverLimit(false);
    }
  }, [questionData.list]);

  // 보기수 갯수에 맞게 보기수 조절
  useEffect(() => {
    // 예/아니오, 서술형시 count가 0이라 빈배열 적용됨으로
    // 선택된 리스트가 없을 경우(보기수 셀렉트 변경시)만 동작
    if (!infoSeq) {
      setQuestionAnswerList((prevList) => {
        const newList = [...prevList];
        const newLength = questionInfo.choice_count;

        if (newLength > newList.length) {
          for (let i = newList.length; i < newLength; i++) {
            newList.push({ choice_title: "", answer: "N" });
          }
        } else if (newLength < newList.length) {
          newList.length = newLength;
        }

        return newList;
      });
    }
  }, [questionInfo.choice_count]);

  useEffect(() => {
    if (infoSeq) {
      api
        .GET_INFO_QUESTION_BANK_DETAIL(infoSeq)
        .then((res) => {
          setQuestionInfo(res);
          setQuestionAnswerList(res.question_details);
        })
        .catch((err) => {
          console.log("info 상세 에러", err);
        });
    }
  }, [infoSeq]);

  // 문제 형식 변경시 초기화 & 단답.다답인지 상태 변경
  useEffect(() => {
    if (!infoSeq) {
      setQuestionAnswerList(lookList);
      setQuestionInfo((prev) => ({
        ...prev,
        points: 10,
        question: "",
        choice_count: 2,
      }));
    }
    if (
      questionInfo.question_form === "P00" ||
      questionInfo.question_form === "P01"
    ) {
      setIsOneOrMulti(true);
    } else {
      setIsOneOrMulti(false);
    }
  }, [questionInfo.question_form]);

  const infoQuestionRequest = () => {
    api
      .GET_INFO_QUESTION_BANK_LIST(modalMainSeq)
      .then((res) => {
        setQuestionData(res);
        setQuestionInfo({
          answer: "Y",
          points: 10,
          question: "",
          choice_count: 2,
          descriptive: "",
          question_form: res.question_type ? res.question_type : "P00",
        });
      })
      .catch((err) => {
        console.log("get info 에러", err);
      });
  };

  // 단답.다답일 경우 lookList
  const choiceTitleChangeHandler = (index, value) => {
    setQuestionAnswerList((prev) =>
      prev.map((item, idx) =>
        idx === index ? { ...item, choice_title: value } : item
      )
    );
  };

  // 단답일 경우 체크박스 1개. 다답일 경우 체크박스 1개이상 선택
  const answerChangeHandler = (index) => {
    setQuestionAnswerList((prevList) => {
      const newList = prevList.map((item, idx) =>
        idx === index
          ? { ...item, answer: item.answer === "Y" ? "N" : "Y" }
          : item
      );

      // 단답
      if (questionInfo.question_form === "P00") {
        return newList.map((item, idx) => ({
          ...item,
          answer: idx === index ? "Y" : "N",
        }));
      } else {
        // 다답
        const checkedItemsCount = newList.filter(
          (item) => item.answer === "Y"
        ).length;

        if (checkedItemsCount === 0) {
          return prevList;
        }
        return newList;
      }
    });
  };

  const initData = () => {
    setInfoSeq(null);
    setQuestionInfo({
      answer: "Y",
      points: 10,
      question: "",
      choice_count: 2,
      descriptive: "",
      question_form: questionData.question_type
        ? questionData.question_type
        : "P00",
    });
    setQuestionAnswerList(lookList);
  };

  // 질문 추가
  const addInfoQuestion = () => {
    initData();
  };

  // 질문 저장
  const saveInfoQuestion = () => {
    if (infoSeq) {
      return;
    }

    if (!questionInfo.question) {
      alert("문제를 입력해주세요");
      return;
    }

    let saveData = {
      ...questionInfo,
      main_seq: modalMainSeq,
      question_details: questionAnswerList,
    };

    // 예/아니오
    if (questionInfo.question_form === "P02") {
      saveData = {
        ...saveData,
        choice_count: 0,
        question_details: [{ choice_title: "", answer: questionInfo.answer }],
      };
    } else if (questionInfo.question_form === "P03") {
      // 서술형
      if (!questionInfo.descriptive) {
        alert("정답을 입력해주세요.");
        return;
      }
      saveData = {
        ...saveData,
        choice_count: 0,
        question_details: [
          { choice_title: "", answer: questionInfo.descriptive },
        ],
      };
    } else {
      // 단답.다답
      const notWrite = questionAnswerList.find((list) => !list.choice_title);

      if (notWrite) {
        alert("보기를 모두 입력해 주세요");
        return;
      }
    }

    api
      .POST_ADD_INFO_QUESTION_BANK(saveData)
      .then((res) => {
        if (res.status === 201) {
          infoQuestionRequest();
          setQuestionAnswerList(lookList);
        }
      })
      .catch((err) => {
        console.log("info seq 에러", err);
      });
  };

  // 질문 삭제
  const deleteInfoQuestion = () => {
    if (!infoSeq) {
      alert("삭제할 리스트를 선택해주세요.");
      return;
    } else {
      if (window.confirm("문제를 삭제하시겠습니까?")) {
        api
          .DELETE_INFO_QUESTION_BANK(infoSeq)
          .then((res) => {
            if (res.status === 200) {
              initData();
              infoQuestionRequest();
            }
          })
          .catch((err) => {
            console.log("info 삭제 에러", err);
          });
      }
    }
  };

  // 모달닫기 & 데이터 초기화
  const closeModal = () => {
    initData();
    setModalMainSeq(null);
    setIsQuestionModalOpen(false);
  };

  return (
    <Modal onClick={closeModal}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h1>문항 상세</h1>
          <button className="modal-close" onClick={closeModal}>
            X
          </button>
        </div>
        <div className="modal-content">
          <QuestionBankModalList
            infoSeq={infoSeq}
            setInfoSeq={setInfoSeq}
            questionData={questionData}
            addInfoQuestion={addInfoQuestion}
            saveInfoQuestion={saveInfoQuestion}
            deleteInfoQuestion={deleteInfoQuestion}
          />
          <div className="question-content-wrap">
            <div className="question-header">
              <h2>
                문항 관리 <span>*문항은 20개까지 등록 가능합니다.</span>
              </h2>
            </div>
            <div className="question-content">
              <table>
                <thead></thead>
                <tbody>
                  <tr>
                    <td className="key">
                      문제형식<span>*</span>
                    </td>
                    <td
                      style={isOneOrMulti ? { width: "18%" } : { width: "34%" }}
                    >
                      {/* 단일일경우 형식 고정 */}
                      {questionData.question_category === "PS00" ? (
                        <div>{questionData.question_type_name}</div>
                      ) : infoSeq ? (
                        <div>{questionInfo["question_form_name"]}</div>
                      ) : (
                        <select
                          id="question_type"
                          value={questionInfo["question_form"]}
                          onChange={(e) =>
                            setQuestionInfo((prev) => ({
                              ...prev,
                              question_form: e.target.value,
                            }))
                          }
                        >
                          {questionType.map((category) => (
                            <option
                              key={category.code_name}
                              value={category.detail_code}
                            >
                              {category.code_name}
                            </option>
                          ))}
                        </select>
                      )}
                    </td>
                    <td className="key">
                      배점<span>*</span>
                    </td>
                    <td
                      style={isOneOrMulti ? { width: "18%" } : { width: "34%" }}
                    >
                      {infoSeq ? (
                        <div>{questionInfo.points}</div>
                      ) : (
                        <input
                          type="text"
                          disabled={isListOverLimit}
                          value={questionInfo.points}
                          onChange={(e) =>
                            setQuestionInfo((prev) => ({
                              ...prev,
                              points: e.target.value,
                            }))
                          }
                        />
                      )}
                    </td>
                    {isOneOrMulti && (
                      <>
                        <td className="key">
                          보기수<span>*</span>
                        </td>
                        <td style={{ width: "18%" }}>
                          {infoSeq ? (
                            <div>{questionInfo["choice_count"]}</div>
                          ) : (
                            <select
                              id="choice_count"
                              disabled={isListOverLimit}
                              value={questionInfo["choice_count"]}
                              onChange={(e) =>
                                setQuestionInfo((prev) => ({
                                  ...prev,
                                  choice_count: e.target.value,
                                }))
                              }
                            >
                              {[2, 3, 4, 5].map((count) => (
                                <option key={count} value={count}>
                                  {count}
                                </option>
                              ))}
                            </select>
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td className="key">
                      문제<span>*</span>
                    </td>
                    <td colSpan="5">
                      {infoSeq ? (
                        <div>{questionInfo.question}</div>
                      ) : (
                        <textarea
                          maxLength={150}
                          disabled={isListOverLimit}
                          value={questionInfo.question}
                          onChange={(e) =>
                            setQuestionInfo((prev) => ({
                              ...prev,
                              question: e.target.value,
                            }))
                          }
                        ></textarea>
                      )}
                    </td>
                  </tr>
                  {isOneOrMulti &&
                    questionAnswerList.map((data, idx) => (
                      <tr key={idx}>
                        <td className="key">
                          보기{idx + 1}
                          <span>*</span>
                          {infoSeq ? (
                            data.answer === "Y" && (
                              <span style={{ color: "#09aa5c" }}>정답</span>
                            )
                          ) : (
                            <span style={{ color: "inherit" }}>
                              (정답
                              <input
                                type="checkbox"
                                disabled={isListOverLimit}
                                checked={data.answer === "Y"}
                                onChange={() => answerChangeHandler(idx)}
                              />
                              )
                            </span>
                          )}
                        </td>
                        <td colSpan="5">
                          {infoSeq ? (
                            <div>{data.choice_title}</div>
                          ) : (
                            <input
                              type="text"
                              value={data.choice_title}
                              disabled={isListOverLimit}
                              onChange={(e) =>
                                choiceTitleChangeHandler(idx, e.target.value)
                              }
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  {questionInfo.question_form === "P02" && (
                    <tr>
                      <td className="key">
                        정답<span>*</span>
                      </td>
                      <td colSpan="5">
                        {infoSeq ? (
                          <div>
                            {questionAnswerList[0].answer === "Y"
                              ? "예"
                              : "아니오"}
                          </div>
                        ) : (
                          [
                            { key: "예", value: "Y" },
                            { key: "아니오", value: "N" },
                          ].map((type) => (
                            <React.Fragment key={type.value}>
                              <input
                                name="yesOrNo"
                                type="radio"
                                id={type.value}
                                value={type.value}
                                disabled={isListOverLimit}
                                checked={questionInfo.answer === type.value}
                                onChange={(e) => {
                                  setQuestionInfo((prev) => ({
                                    ...prev,
                                    answer: e.target.value,
                                  }));
                                }}
                              />
                              <label htmlFor={type.value}>{type.key}</label>
                            </React.Fragment>
                          ))
                        )}
                      </td>
                    </tr>
                  )}
                  {questionInfo.question_form === "P03" && (
                    <tr>
                      <td className="key">
                        정답<span>*</span>
                      </td>
                      <td colSpan="5">
                        {infoSeq ? (
                          <div>{questionAnswerList[0].answer}</div>
                        ) : (
                          <input
                            type="text"
                            disabled={isListOverLimit}
                            value={questionInfo.descriptive}
                            onChange={(e) => {
                              setQuestionInfo((prev) => ({
                                ...prev,
                                descriptive: e.target.value,
                              }));
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default QuestionBankModal;
