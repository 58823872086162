// 상담사 - 상담 리포트 - 프린트 팝업

import { useEffect, useRef } from "react";
import styled from "styled-components";

import { useReactToPrint } from "react-to-print";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const PopupContainer = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  height: 100%;
  width: 1000px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Popup = ({ show, onClose, children }) => {
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      onClose();
    },
  });

  const printRef = useRef();
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        handlePrint();
      }, 200);
    }
  }, [show]);

  if (!show) return null;
  return (
    <Overlay>
      <PopupContainer>
        <div ref={printRef}>{children}</div>
      </PopupContainer>
    </Overlay>
  );
};

export default Popup;
