// 내담자 마이페이지

//* hooks
import { useState, useEffect } from "react";

//* style
import { MainLayout } from "@styles/common";

//* api
import { api } from "@api";

//* components
import Tab from "@components/common/Tab";
import Header from "@components/common/Header";
import JoinForm from "@components/auth/JoinForm";

function MyInfoPage() {
  //* state
  const [info, setInfo] = useState({
    userid: "",
    username: "",
    userpass: "",
    userpassCheck: "",
    cellphone: "",
    email: "",
    year: "",
    month: "",
    date: "",
  });
  const [activeTab, setActiveTab] = useState(1);

  const tabList = ["나의 상담", " 내 정보"];

  useEffect(() => {
    myInfo();
  }, []);

  const myInfo = async () => {
    try {
      const myInfoResponse = await api.GET_MY_INFO();

      const birthday = myInfoResponse.birthday;
      const year = birthday.slice(0, 4);
      const month = birthday.slice(4, 6);
      const date = birthday.slice(6, 8);

      setInfo({
        ...myInfoResponse,
        year,
        month,
        date,
        userpassCheck: myInfoResponse.userpass,
      });
    } catch (err) {
      console.log("내 정보 요청 err", err);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <Tab
          tabList={tabList}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div id="myInfoArea">
          <JoinForm initialValues={info} myInfo={myInfo} />
        </div>
      </MainLayout>
    </>
  );
}

export default MyInfoPage;
