//* hooks
import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";

//* style
import { CommonButton, QuestionListHeader } from "@styles/common";

//* component
import DataTable from "@components/common/DataTable";

function QuestionBankList({
  type,
  infoSeq,
  mainSeq,
  tableData,
  setInfoSeq,
  setMainSeq,
  setModalMainSeq,
  setIsQuestionModalOpen,
}) {
  const columnHelper = createColumnHelper();

  const columns = useMemo(() => {
    const baseColumns = [
      columnHelper.accessor("NO", {
        header: "NO",
        size: 10,
        cell: ({ row, table }) =>
          (table
            .getSortedRowModel()
            ?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1,
      }),
    ];

    if (type === "questionBank") {
      // 문제은행 페이지 리스트
      baseColumns.push(
        columnHelper.accessor("question_name", {
          header: "문제은행명",
          size: 200,
        }),
        columnHelper.accessor("inspection_type_name", {
          header: "검사구분",
          size: 80,
        }),
        columnHelper.accessor("user_name", {
          header: "출제자",
          size: 80,
        }),
        columnHelper.accessor("question_info_count", {
          header: "문항수",
          size: 80,
        }),
        columnHelper.accessor("use_type_name", {
          header: "사용여부",
          size: 80,
        }),
        columnHelper.accessor("subject", {
          header: "문제편집",
          size: 50,
          cell: ({ row }) => {
            const handleEditClick = () => {
              setIsQuestionModalOpen(true);
              setModalMainSeq(row.original.main_seq);
            };

            return (
              <CommonButton
                onClick={handleEditClick}
                style={{
                  width: "100%",
                  color: "#09aa5c",
                  background: "#fff",
                  border: "1px solid #09aa5c",
                }}
              >
                편집
              </CommonButton>
            );
          },
        })
      );
    } else if (type === "questionBankModal") {
      // 문제은행 모달 리스트
      baseColumns.push(
        columnHelper.accessor("question", {
          header: "문제",
          size: 200,
        }),
        columnHelper.accessor("question_form_name", {
          header: "문제형식",
          size: 80,
        }),
        columnHelper.accessor("choice_count", {
          header: "보기수",
          size: 80,
          cell: (info) => (info.getValue() === 0 ? "-" : info.getValue()),
        }),
        columnHelper.accessor("points", {
          header: "배점",
          size: 80,
        })
      );
    } else if (type === "questionBankPool") {
      // 진단검사 모달 문제은행 리스트
      baseColumns.push(
        columnHelper.accessor("question_name", {
          header: "문제은행명",
          size: 220,
        }),
        columnHelper.accessor("inspection_type_name", {
          header: "검사구분",
          size: 80,
        }),
        columnHelper.accessor("user_name", {
          header: "출제자",
          size: 80,
        }),
        columnHelper.accessor("question_info_count", {
          header: "문항수",
          size: 80,
        }),
        columnHelper.accessor("subject", {
          header: "POOL 선택",
          size: 50,
          cell: ({ row }) => {
            const handleEditClick = () => {
              setMainSeq(row.original.main_seq);
            };

            return (
              <CommonButton
                onClick={handleEditClick}
                style={{
                  width: "100%",
                  color: "#09aa5c",
                  background: "#fff",
                  border: "1px solid #09aa5c",
                }}
              >
                추가
              </CommonButton>
            );
          },
        })
      );
    }

    return baseColumns;
  }, [type]);

  return (
    <>
      {type === "questionBank" && (
        <QuestionListHeader>
          <h2>문제은행 목록</h2>
        </QuestionListHeader>
      )}

      <DataTable
        type={type}
        columns={columns}
        infoSeq={infoSeq}
        mainSeq={mainSeq}
        tableData={tableData}
        setInfoSeq={setInfoSeq}
        setMainSeq={setMainSeq}
      />
    </>
  );
}

export default QuestionBankList;
