// 내담자 나의 상담 - 화상채팅

//* hooks
import io from "socket.io-client";
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";

//* style
import { CommonButton, ConsultationAreaWrap } from "@styles/common";

//* api
import { api } from "@api";

//* components
import ScatterplotChart from "@components/common/ScatterplotChart";

//* func
import { func } from "@func";

function ConsultationArea({
  seq,
  isSave,
  cvChart,
  setCvChart,
  severityChart,
  setSeverityChart,
  consultationDetailData,
}) {
  //* state
  const [isAudio, setIsAudio] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isConnection, setIsConnection] = useState(false);

  const [allStt, setAllStt] = useState([
    // "안녕하세요, 무엇을 도와드릴까요? 심각",
    // "제가 최근에 업무 스트레스로 고통 받고 주의 있어요",
    // "그러시군요, 그 업무 스트레스 때문에 일상위험 생활에 어떤 영향을 받고 계신가요?",
    // "요즘엔 잠도 잘 못 자고, 집중력이 많이 떨어지는 것 같습니다. 아 죽겠다",
    // "그런 상황에서는 정말 힘드실 텐데요. 상담자님께서는 스트레스 해소를 위해 어떤 방법을 시도해 보셨나요?",
    // "너무 우울해요.",
  ]);
  const [singleStt, setSingleStt] = useState([]);
  const [clientStt, setClientStt] = useState([]);
  const [counselorStt, setCounselorStt] = useState([]);

  const [canvasApply, setCanvasApply] = useState(null); // clientVideoRef, counselorVideoRef 중 canvas를 올릴 ref
  const [isRecording, setIsRecording] = useState(false); // single webrtc일 경우 녹화 & 요청 여부
  const [noticeKeywords, setNoticeKeywords] = useState([]);
  const [pairedWhisperFileCnt, setPairedWhisperFileCnt] = useState(0); // paired 일 경우 서버로 보낼 파일명에 포함될 숫자
  const [singleWhisperFileCnt, setSingleWhisperFileCnt] = useState(0); // single 일 경우 서버로 보낼 파일명에 포함될 숫자

  const [timer, setTimer] = useState(0);
  const [cvEmotion, setCvEmotion] = useState("");
  const [isTimerActive, setIsTimerActive] = useState(false);

  //* ref
  const timerRef = useRef(null);
  const canvasRef = useRef(null);
  const sttAreaRef = useRef(null);
  const clientVideoRef = useRef(null);
  const isInitialMount = useRef(true);
  const isInitialMount2 = useRef(true);
  const counselorVideoRef = useRef(null);
  const clientMediaRecorderRef = useRef(null);
  const counselorMediaRecorderRef = useRef(null);
  const entireClientMediaRecorderRef = useRef(null);
  const entireCounselorMediaRecorderRef = useRef(null);

  //* let & const
  let pc;
  const whisperRecordingTime = 10000; // whisper 녹화 시간
  const isCounselor = localStorage.getItem("user_type") === "상담사";
  const username = isCounselor ? "상담사" : "내담자";
  const socket = io("http://192.168.0.28:5004", {
    // const socket = io("/", {
    // path: process.env.REACT_APP_SOCKET_PATH,
    autoConnect: false,
  });

  const isConsultationVideo =
    consultationDetailData.counsel_way === "녹음(녹화)";

  //* useEffect
  useEffect(() => {
    if (sttAreaRef.current) {
      sttAreaRef.current.scrollTop = sttAreaRef.current.scrollHeight;
    }
  }, [allStt]);

  useEffect(() => {
    const videoRef = isCounselor ? counselorVideoRef : clientVideoRef;

    if (videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();

      tracks.forEach((track) => {
        if (
          (isVideo && track.kind === "video") ||
          (isAudio && track.kind === "audio")
        ) {
          track.enabled = false;
        } else {
          track.enabled = true;
        }
      });
    }
  }, [isVideo, isAudio]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (isConnection) {
        startConnection();
      }
    }
  }, [isConnection]);

  useEffect(() => {
    if (isSave) {
      if (!isConsultationVideo) {
        if (counselorVideoRef.current.srcObject) {
          counselorVideoRef.current.srcObject
            .getTracks()
            .forEach((track) => track.stop());
        }

        if (clientVideoRef.current.srcObject) {
          clientVideoRef.current.srcObject
            .getTracks()
            .forEach((track) => track.stop());
        }
      }
    }
  }, [isSave]);

  useEffect(() => {
    noticeKeywordsRequest();

    const video = isCounselor
      ? counselorVideoRef.current
      : clientVideoRef.current;

    if (video && video.readyState >= 2) {
      resizeCanvas();
    } else {
      video.addEventListener("loadedmetadata", resizeCanvas);
    }
    window.addEventListener("resize", resizeCanvas);

    const savedVideoRef = video;

    return () => {
      if (savedVideoRef.srcObject) {
        savedVideoRef.srcObject.getTracks().forEach((track) => track.stop());
      }

      window.removeEventListener("resize", resizeCanvas);
      video.removeEventListener("loadedmetadata", resizeCanvas);
    };
  }, []);

  useEffect(() => {
    const captureAndSend = () => {
      if (isRecording && canvasApply) {
        let ctx = canvasRef.current.getContext("2d");

        ctx.drawImage(
          canvasApply.current,
          0,
          0,
          canvasRef.current.offsetWidth,
          canvasRef.current.offsetHeight
        );

        const imageData = canvasRef.current.toDataURL("image/jpeg");

        api
          .POST_OPEN_CV({ image: imageData })
          .then((response) => {
            const { emotions, faceCoordinates } = response.response;
            drawRectangle(emotions, faceCoordinates);
            setCvEmotion(emotions[0]);
          })
          .catch((error) => {
            console.error("서버 요청 중 에러 발생:", error);
          });
      }
    };
    const intervalId = setInterval(captureAndSend, 1000);

    return () => clearInterval(intervalId);
  }, [isRecording, canvasApply]);

  useEffect(() => {
    if (
      clientMediaRecorderRef.current &&
      counselorMediaRecorderRef.current &&
      isRecording
    ) {
      pairedWhisperHandler();
    }
  }, [pairedWhisperFileCnt]);

  useEffect(() => {
    if (counselorMediaRecorderRef.current && isRecording) {
      singleWhisperHandler();
    }
  }, [singleWhisperFileCnt]);

  useEffect(() => {
    const isPaired =
      clientMediaRecorderRef.current && counselorMediaRecorderRef.current;

    if (isRecording) {
      if (isPaired) {
        setCanvasApply(clientVideoRef);
        pairedWhisperHandler();
        clientVideoRef.current.parentNode.insertBefore(
          canvasRef.current,
          clientVideoRef.current.nextSibling
        );
      } else {
        setCanvasApply(counselorVideoRef);
        singleWhisperHandler();
        counselorVideoRef.current.parentNode.insertBefore(
          canvasRef.current,
          counselorVideoRef.current.nextSibling
        );
      }
      canvasRef.current.style.position = "absolute";
      canvasRef.current.style.top = "0";
      canvasRef.current.style.left = "0";
      canvasRef.current.style.width = clientVideoRef.current.offsetWidth;
      canvasRef.current.style.height = clientVideoRef.current.offsetHeight;
    }
  }, [isRecording]);

  useEffect(() => {
    const isPaired =
      clientMediaRecorderRef.current && counselorMediaRecorderRef.current;

    if (!isInitialMount2.current) {
      if (isRecording) {
        isPaired && entireClientMediaRecorderRef.current.start();
        entireCounselorMediaRecorderRef.current.start();
      } else {
        isPaired && entireClientMediaRecorderRef.current.stop();
        entireCounselorMediaRecorderRef.current.stop();

        if (isPaired) {
          entireClientMediaRecorderRef.current.ondataavailable = function (
            event1
          ) {
            const clientVideo = new Blob([event1.data], {
              type: "video/mp4",
            });

            entireCounselorMediaRecorderRef.current.ondataavailable = function (
              event2
            ) {
              const adminVideo = new Blob([event2.data], {
                type: "video/mp4",
              });

              let formData = new FormData();
              formData.append("seq", seq);
              formData.append("clientVideo", clientVideo);
              formData.append("adminVideo", adminVideo);

              const entireVideoReq =
                api.POST_ENTIRE_CONSULTATION_VIDEO(formData);
            };
          };
        } else {
          entireCounselorMediaRecorderRef.current.ondataavailable = function (
            event2
          ) {
            const adminVideo = new Blob([event2.data], {
              type: "video/mp4",
            });

            let formData = new FormData();
            formData.append("seq", seq);
            formData.append("adminVideo", adminVideo);

            const entireVideoReq = api.POST_ENTIRE_CONSULTATION_VIDEO(formData);
          };
        }
      }
    } else {
      isInitialMount2.current = false;
    }
  }, [isRecording]);

  useEffect(() => {
    if (isTimerActive) {
      timerRef.current = setInterval(() => {
        setTimer((prevSeconds) => prevSeconds + 1);
      }, 1000);
    }
    return () => clearInterval(timerRef.current);
  }, [isTimerActive]);

  const time = Math.round(timer / 60);

  useEffect(() => {
    const noticeKeywordsList = noticeKeywords.map((data) => data.keyword);

    if (clientStt[clientStt.length - 1]) {
      clientStt[clientStt.length - 1]
        .split(new RegExp(`(${noticeKeywordsList.join("|")})`))
        .map((part) => {
          if (noticeKeywordsList.includes(part)) {
            const severity = noticeKeywords.find(
              (item) => item.keyword === part
            ).severity;

            setSeverityChart((prevState) => {
              return prevState.map((item) => {
                if (item.id === severity) {
                  return {
                    ...item,
                    data: [...item.data, { x: time, y: severity }],
                  };
                } else {
                  return { ...item };
                }
              });
            });
          }
        });
    }
  }, [clientStt]);

  useEffect(() => {
    const cvChartUpdate = () => {
      if (isRecording) {
        setCvChart((prevState) => {
          return prevState.map((item) => {
            if (item.id === cvEmotion) {
              return {
                ...item,
                data: [...item.data, { x: time, y: cvEmotion }],
              };
            } else {
              return { ...item };
            }
          });
        });
      }
    };
    const cvChartInterval = setInterval(cvChartUpdate, 5000);

    return () => clearInterval(cvChartInterval);
  }, [cvEmotion, isRecording]);

  //* webrtc & socket
  const startConnection = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true,
      })
      .then((stream) => {
        if (isCounselor) {
          counselorVideoRef.current.srcObject = stream;
          counselorMediaRecorderRef.current = new MediaRecorder(stream);
          entireCounselorMediaRecorderRef.current = new MediaRecorder(stream);
        } else {
          clientVideoRef.current.srcObject = stream;
          clientMediaRecorderRef.current = new MediaRecorder(stream);
          entireClientMediaRecorderRef.current = new MediaRecorder(stream);
        }
        // vadHandler(stream);
        socket.connect();
        socket.emit("join", {
          username,
          room: "1",
        });
      })
      .catch((error) => {
        console.error("Stream not found: ", error);
      });
  };

  const resizeCanvas = () => {
    const video = counselorVideoRef.current;
    const canvas = canvasRef.current;

    if (video.videoWidth && video.videoHeight) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
    }
  };

  const onTrack = (event) => {
    if (isCounselor) {
      clientVideoRef.current.srcObject = event.streams[0];
      clientMediaRecorderRef.current = new MediaRecorder(event.streams[0]);
      entireClientMediaRecorderRef.current = new MediaRecorder(
        event.streams[0]
      );
    } else {
      counselorVideoRef.current.srcObject = event.streams[0];
      counselorMediaRecorderRef.current = new MediaRecorder(event.streams[0]);
      entireCounselorMediaRecorderRef.current = new MediaRecorder(
        event.streams[0]
      );
    }
  };

  const sendData = (data) => {
    socket.emit("data", {
      username,
      room: "1",
      data: data,
    });
  };

  const onIceCandidate = (event) => {
    if (event.candidate) {
      sendData({
        type: "candidate",
        candidate: event.candidate,
      });
    }
  };

  const createPeerConnection = () => {
    try {
      pc = new RTCPeerConnection({
        iceServers: [
          {
            urls: ["turn:mentalitycare.kr:3478?transport=tcp"],
            username: "tuser",
            credential: "tpass",
          },
          // {
          //   urls: "stun:openrelay.metered.ca:80",
          // },
          // {
          //   urls: "turn:openrelay.metered.ca:80",
          //   username: "openrelayproject",
          //   credential: "openrelayproject",
          // },
          // {
          //   urls: "turn:openrelay.metered.ca:443",
          //   username: "openrelayproject",
          //   credential: "openrelayproject",
          // },
          // {
          //   urls: "turn:openrelay.metered.ca:443?transport=tcp",
          //   username: "openrelayproject",
          //   credential: "openrelayproject",
          // },
        ],
      });
      pc.ontrack = onTrack; // 피어로부터 오디오나 비디오 트랙이 수신될 때 호출될 함수
      pc.onicecandidate = onIceCandidate; // ICE candidate(피어 간에 서로의 네트워크 정보를 교환) 이벤트가 발생할 때 호출될 함수

      const videoRef = counselorVideoRef.current.srcObject
        ? counselorVideoRef.current.srcObject
        : clientVideoRef.current.srcObject;

      for (const track of videoRef.getTracks()) {
        pc.addTrack(track, videoRef);
      }
    } catch (error) {
      console.error("PeerConnection failed: ", error);
    }
  };

  const setAndSendLocalDescription = (sessionDescription) => {
    pc.setLocalDescription(sessionDescription);
    sendData(sessionDescription);
  };

  const signalingDataHandler = (data) => {
    console.log("signaling data type", data.type);
    if (data.type === "offer") {
      createPeerConnection();
      pc.setRemoteDescription(new RTCSessionDescription(data));
      pc.createAnswer().then(setAndSendLocalDescription, (error) => {
        console.error("Send answer failed: ", error);
      });
    } else if (data.type === "answer") {
      pc.setRemoteDescription(new RTCSessionDescription(data));
    } else if (data.type === "candidate") {
      pc.addIceCandidate(new RTCIceCandidate(data.candidate));
    }
  };

  socket.on("ready", () => {
    createPeerConnection();
    pc.createOffer().then(setAndSendLocalDescription, (error) => {
      console.error("Send offer failed: ", error);
    });
  });

  socket.on("data", (data) => {
    console.log("!!!", data);
    signalingDataHandler(data);
  });

  socket.on("whisper2", (data) => {
    console.log("data", data);
    setSingleStt((prevText) => [...prevText, data]);
  });

  socket.on("audio_result", (data) => {
    console.log("audio_result!!!", data);
  });

  socket.on("test", (data) => {
    console.log("test data", data);
  });

  //* whisper
  const pairedWhisperHandler = () => {
    if (isCounselor) {
      if (counselorMediaRecorderRef.current.state === "inactive") {
        counselorMediaRecorderRef.current.start();
        clientMediaRecorderRef.current.start();

        setTimeout(() => {
          if (counselorMediaRecorderRef.current.state === "recording") {
            setPairedWhisperFileCnt((prevCnt) => prevCnt + 1);
            counselorMediaRecorderRef.current.stop();
            clientMediaRecorderRef.current.stop();

            counselorMediaRecorderRef.current.ondataavailable = function (
              event1
            ) {
              const counselorMedia = new Blob([event1.data], {
                type: "audio/wav",
              });

              clientMediaRecorderRef.current.ondataavailable = function (
                event2
              ) {
                const clientMedia = new Blob([event2.data], {
                  type: "audio/wav",
                });

                let formData = new FormData();
                formData.append("seq", seq);
                formData.append("file1", counselorMedia, `pair_one`);
                formData.append("file2", clientMedia, `pair_two`);

                api
                  .POST_WHISPER_PAIR(formData)
                  .then((res) => {
                    console.log("pair whisper 결과", res);
                    const message = res.message;
                    setClientStt((prevText) => [
                      ...prevText,
                      message[1].message,
                    ]);
                    setCounselorStt((prevText) => [
                      ...prevText,
                      message[0].message,
                    ]);
                    setAllStt((prevText) => [...prevText, ...message]);
                  })
                  .catch((error) => {
                    console.error("pair whisper 에러", error);
                  });
              };
            };
          }
        }, whisperRecordingTime);
      }
    }
  };

  const singleWhisperHandler = () => {
    if (counselorMediaRecorderRef.current.state === "inactive") {
      counselorMediaRecorderRef.current.start();

      setTimeout(() => {
        if (counselorMediaRecorderRef.current.state === "recording") {
          setSingleWhisperFileCnt((prevCnt) => prevCnt + 1);
          counselorMediaRecorderRef.current.stop();

          counselorMediaRecorderRef.current.ondataavailable = function (
            event1
          ) {
            const counselorMedia = new Blob([event1.data], {
              type: "video/mp4",
            });

            let formData = new FormData();
            formData.append("seq", seq);
            formData.append("file1", counselorMedia, `single.mp4`);

            api
              .POST_WHISPER_SINGLE(formData)
              .then((res) => {
                console.log("single whisper 결과", res.message);
                setSingleStt((prevText) => [...prevText, res.message]);
              })
              .catch((error) => {
                console.error("single whisper 에러", error);
              });
          };
        }
      }, whisperRecordingTime);
    }
  };

  const playClientVideo = () => {
    socket.connect();
    socket.emit("whisper2", { seq: seq });
  };

  //* open cv
  const drawRectangle = (emotions, faceCoordinates) => {
    const ctx = canvasRef.current.getContext("2d");

    canvasRef.current.style.width =
      counselorMediaRecorderRef.current.offsetWidth;
    canvasRef.current.style.height =
      counselorMediaRecorderRef.current.offsetHeight;

    ctx.clearRect(
      0,
      0,
      canvasRef.current.offsetWidth,
      canvasRef.current.offsetHeight
    );

    ctx.strokeStyle = "#33ff33";
    ctx.lineWidth = 2;

    ctx.strokeRect(
      faceCoordinates[0].x,
      faceCoordinates[0].y,
      faceCoordinates[0].width,
      faceCoordinates[0].height
    );

    ctx.font = "16px Arial";
    ctx.fillStyle = "#33ff33";
    ctx.fillText(emotions[0], faceCoordinates[0].x, faceCoordinates[0].y - 10);
  };

  const noticeKeywordsRequest = async () => {
    try {
      const noticeKeywordsResponse = await api.POST_NOTICE_KEYWORDS_LIST({});
      noticeKeywordsResponse.list.map((data) => data.keyword);
      setNoticeKeywords(noticeKeywordsResponse.list);
    } catch (err) {
      console.log("알림 키워드 리스트 err", err);
      alert("알림 키워드 리스트 err", err);
    }
  };

  const leaveRoom = () => {
    socket.emit("leave", {
      username,
      room: "1",
    });
  };

  const stopConnection = () => {
    if (isCounselor && counselorVideoRef.current.srcObject) {
      counselorVideoRef.current.srcObject
        .getTracks()
        .forEach((track) => track.stop());
    }

    if (!isCounselor && clientVideoRef.current.srcObject) {
      clientVideoRef.current.srcObject
        .getTracks()
        .forEach((track) => track.stop());
    }

    leaveRoom();
  };

  return (
    <>
      {!isConsultationVideo && (
        <ConsultationControlContainer>
          <VideoControlContainer>
            <CommonButton onClick={() => setIsAudio(!isAudio)}>
              마이크 {isAudio ? "켜기" : "끄기"}
            </CommonButton>
            <CommonButton onClick={() => setIsVideo(!isVideo)}>
              카메라 {isVideo ? "켜기" : "끄기"}
            </CommonButton>
            <CommonButton
              onClick={() => {
                setIsConnection(!isConnection);
                isConnection && stopConnection();
              }}
            >
              연결 {isConnection ? "끊기" : "하기"}
            </CommonButton>
          </VideoControlContainer>

          {isCounselor && (
            <ButtonContainer>
              <CommonButton
                type="button"
                onClick={() => {
                  console.log("시작");
                  setIsRecording(true);
                  setIsTimerActive(true);
                }}
              >
                시작
              </CommonButton>
              <CommonButton
                type="button"
                onClick={() => {
                  console.log("종료");
                  setIsRecording(false);
                  setIsTimerActive(false);
                  canvasRef.current.style.display = "none";
                }}
              >
                종료
              </CommonButton>
            </ButtonContainer>
          )}
        </ConsultationControlContainer>
      )}

      <ConsultationAreaWrap>
        <div className="consultation-area">
          <div className="key">상담자</div>
          <div className="consultation-content">
            <div>
              {isConsultationVideo ? (
                <video controls onPlay={playClientVideo} style={{ width: 600 }}>
                  <source
                    src={`${process.env.REACT_APP_API_URL}videoPlay?seq=${seq}&type=녹음(녹화)`}
                  />
                </video>
              ) : (
                <video autoPlay ref={clientVideoRef} />
              )}
            </div>
          </div>
        </div>
        <canvas ref={canvasRef}></canvas>
        <div className="consultation-area">
          <div className="key">상담사</div>
          <div
            className={`consultation-content ${
              isConsultationVideo && "disabled"
            }`}
          >
            <div>
              <video autoPlay ref={counselorVideoRef} />
            </div>
          </div>
        </div>
      </ConsultationAreaWrap>

      {isCounselor && (
        <ConsultationAreaWrap>
          <div className="consultation-area">
            <div className="key">내담자 감정 상태 그래프</div>
            <div id="consultationGraph">
              <div>
                <ScatterplotChart type="cv" data={cvChart} />
                <ScatterplotChart type="severity" data={severityChart} />
              </div>
            </div>
          </div>
          <div className="consultation-area">
            <div className="key">상담내용</div>
            <div id="consultationContentWrap" ref={sttAreaRef}>
              <div id="consultationContent">
                {singleStt.map((text, idx) => (
                  <div key={idx}>{text}</div>
                ))}
                {allStt.map((text, idx) => (
                  <div key={idx} className="speech-bubble">
                    <p>
                      <span
                        style={{ color: idx % 2 === 0 ? "#3ACA9A" : "#6495ED" }}
                      >
                        {idx % 2 === 0 ? "상담사" : "내담자"}
                      </span>
                      :
                      {idx % 2 === 0
                        ? text.message
                        : func.noticeTextHandler(text.message, noticeKeywords)}
                    </p>
                    <span className="date">{text.time}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </ConsultationAreaWrap>
      )}
    </>
  );
}

const ConsultationControlContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-end;

  button:first-child {
    background-color: ${({ theme }) => theme.color["primary-2"]};
  }

  button:nth-child(2) {
    margin-left: 0.4rem;
    background-color: tomato;
  }
`;

const VideoControlContainer = styled.div`
  margin-bottom: 1rem;

  button:nth-child(1),
  button:nth-child(2) {
    margin-right: 0.4rem;
  }
`;

export default ConsultationArea;
