//* hooks
import { useState, useEffect, useRef } from "react";

//* style
import {
  ConsultationAreaWrap,
  CommonConsultingDetailContainer,
} from "@styles/common";

//* api
import { api } from "@api";

//* components
import BarChart from "@components/common/BarChart";
import RadarChart from "@components/common/RadarChart";

function ConsultationReport({ seq }) {
  const [cvChart, setCvChart] = useState([]);
  const [voiceChart, setVoiceChart] = useState([]);
  const [cvVoiceChart, setCvVoiceChart] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [keywordChart, setKeywordChart] = useState([]);
  const [utteranceKeyword, setUtteranceKeyword] = useState([]);

  useEffect(() => {
    async function report() {
      const report = await api.POST_CONSULTATION_REPORT({ seq });
      setReportData(report);

      setVoiceChart(report.corpus_results);

      const keywordFilteredData = report.keyword_results.filter(
        (keyword) => keyword.count >= 1
      );
      setUtteranceKeyword(keywordFilteredData);

      const allKeywordLength = Object.keys(report.keyword_results).length;
      const keywordChartData = report.keyword_results
        .filter((data) => data.count >= 1 && data.keyword)
        .map((data) => ({
          keyword: data.keyword,
          count: Math.round((data.count / allKeywordLength) * 100),
        }));
      setKeywordChart(keywordChartData);

      const cvChartData = report.cv_chart_data_counts.map((data) => ({
        ...data,
        안면: Math.floor((data.count / 7) * 100),
      }));
      setCvChart(cvChartData);

      const mergedData = cvChartData.map((item1) => {
        const item2 = report.corpus_results.find(
          (item) => item.emotion === item1.emotion
        );
        return {
          emotion: item1.emotion,
          안면: item1.안면,
          음성: item2.음성,
        };
      });
      setCvVoiceChart(mergedData);
    }
    report();
  }, []);

  const printRef = useRef();

  return (
    <CommonConsultingDetailContainer
      ref={printRef}
      width="16.6%"
      style={{ marginTop: "1rem" }}
    >
      <table style={{ marginBottom: "0rem" }}>
        <thead>
          <tr>
            <th className="key">상담일</th>
            <th>{reportData.counsel_dt}</th>
            <th className="key">상담방법</th>
            <th>{reportData.counsel_way}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="key">상담자</td>
            <td colSpan="3">{reportData.client_username}</td>
          </tr>
          <tr>
            <td className="key">상담명</td>
            <td colSpan="3">{reportData.subject}</td>
          </tr>
          <tr>
            <td className="key">상담 요청 내용</td>
            <td colSpan="3">{reportData.request_details}</td>
          </tr>
        </tbody>
      </table>

      <ConsultationAreaWrap style={{ borderTop: "none" }}>
        <div className="consultation-area">
          <div className="key">안면 인식 감정</div>
          {cvChart.length ? <RadarChart data={cvChart} type="cv" /> : null}
        </div>
        <div className="consultation-area">
          <div className="key">음성 인식 감정</div>
          {cvChart.length ? (
            <RadarChart data={voiceChart} type="voice" />
          ) : null}
        </div>
        <div className="consultation-area">
          <div className="key">안면과 음성의 감정 비교</div>
          {cvVoiceChart.length ? (
            <RadarChart data={cvVoiceChart} type="multi" />
          ) : null}
        </div>
      </ConsultationAreaWrap>

      <ConsultationAreaWrap style={{ borderTop: "none", borderBottom: "none" }}>
        <div className="consultation-area " style={{ width: "33.3%" }}>
          <div className="key">내담자 발화 알림 키워드</div>
          <div style={{ margin: "0.8rem" }}>
            {utteranceKeyword.map((data, index) => (
              <span key={data.keyword}>
                {data.keyword}
                {index < data.keyword.length - 1 && ","}
              </span>
            ))}
          </div>
        </div>

        <div
          className="consultation-area history-chart"
          style={{ width: "66.7%" }}
        >
          <div className="key">내담자 알림 키워드 발화 횟수</div>
          {/* <div> */}
          <BarChart data={keywordChart} />
          {/* </div> */}
        </div>
      </ConsultationAreaWrap>

      <table style={{ marginBottom: "0rem" }}>
        <tbody>
          <tr>
            <td className="key" style={{ width: "25%" }}>
              설문 검사 결과
            </td>
            <td colSpan="3"></td>
          </tr>
          <tr>
            <td className="key" style={{ width: "25%" }}>
              상담 요약
            </td>
            <td colSpan="3"></td>
          </tr>
          <tr>
            <td className="key" style={{ width: "25%" }}>
              상담 조치
            </td>
            <td colSpan="3">{reportData.action_details}</td>
          </tr>
          <tr>
            <td className="key" style={{ width: "25%" }}>
              차기 상담일시
            </td>
            <td colSpan="3">{reportData.is_next_sch}</td>
          </tr>
        </tbody>
      </table>
    </CommonConsultingDetailContainer>
  );
}

export default ConsultationReport;
