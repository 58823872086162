// 설문관리 - 진단검사 모달 리스트

//* hooks
import { useState } from "react";

//* style
import { CommonButton } from "@styles/common";

//* components
import QuestionBankList from "@components/counselor/QuestionBankList";

function QuestionBankPoolModalList({
  tableData,
  setMainSeq,
  inspectionType,
  getQuestionBankList,
}) {
  const [type, setType] = useState(inspectionType[0].detail_code);

  return (
    <div className="question-list-wrap">
      <div className="question-header">
        <h2>문제은행 POOL</h2>
        <div className="select-container">
          <p>검사구분:</p>
          <select
            id="inspection_type"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            {inspectionType.map((inspection) => (
              <option key={inspection.code_name} value={inspection.detail_code}>
                {inspection.code_name}
              </option>
            ))}
          </select>
          <CommonButton onClick={() => getQuestionBankList(type)}>
            검색
          </CommonButton>
        </div>
      </div>
      <div className="question-list">
        <QuestionBankList
          type="questionBankPool"
          tableData={tableData}
          setMainSeq={setMainSeq}
        />
      </div>
    </div>
  );
}

export default QuestionBankPoolModalList;
