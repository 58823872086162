// 비밀번호 찾기 - 비밀번호 변경

//* hooks
import * as Yup from "yup";
import { useFormik } from "formik";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

//* style
import { CommonButton, CommonRequiedMessage } from "@styles/common";

//* api
import { api } from "@api";

const PwChangeContainer = styled.div`
  width: 350px;
  display: flex;
  align-items: center;
  flex-direction: column;

  #idInfo {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    > p:first-child {
      font-weight: bold;
      margin-right: 1.2rem;
    }
  }

  form {
    width: 80%;
    display: flex;
    flex-direction: column;

    input {
      margin: 0.6rem 0rem;
    }

    button {
      margin-top: 2.4rem;
    }
  }
`;

function ChangePW({ id }) {
  const navigate = useNavigate();

  const { handleSubmit, handleChange, values, touched, errors, handleBlur } =
    useFormik({
      initialValues: {
        userpass: "",
        userpassCheck: "",
      },
      validationSchema: Yup.object().shape({
        userpass: Yup.string().required("새 비밀번호를 입력해 주세요."),
        userpassCheck: Yup.string()
          .oneOf([Yup.ref("userpass"), null], "비밀번호가 일치하지 않습니다.")
          .required("새 비밀번호 확인을 입력해 주세요."),
      }),
      onSubmit: async (values) => {
        try {
          const changePWResponse = await api.POST_CHANGE_PW({
            ...values,
            userid: id,
          });
          alert(changePWResponse.message);
          navigate("/login");
        } catch (err) {
          console.log("비밀번호 변경 err", err);
          alert(err.response.data.message);
        }
      },
    });

  return (
    <>
      <h2>안전한 비밀번호로 변경해 주시기 바랍니다.</h2>

      <PwChangeContainer>
        <div id="idInfo">
          <p>아이디</p>
          <p id="userID">{id}</p>
        </div>

        <form onSubmit={handleSubmit}>
          <input
            id="userpass"
            name="userpass"
            type="password"
            value={values.userpass}
            onChange={handleChange}
            placeholder="새 비밀번호"
          />
          {touched.userpass && errors.userpass && (
            <CommonRequiedMessage>{errors.userpass}</CommonRequiedMessage>
          )}

          <input
            type="password"
            id="userpassCheck"
            name="userpassCheck"
            onChange={handleChange}
            value={values.userpassCheck}
            placeholder="새 비밀번호 확인"
          />
          {touched.userpassCheck && errors.userpassCheck && (
            <CommonRequiedMessage>{errors.userpassCheck}</CommonRequiedMessage>
          )}

          <CommonButton type="submit" width={"100%"} fontSize={"1.2rem"}>
            확인
          </CommonButton>
        </form>
      </PwChangeContainer>
    </>
  );
}

export default ChangePW;
