// 상담사 - 상담 리포트

//* hooks
import { useEffect, useState, useMemo } from "react";

//* style
import { MainLayout } from "@styles/common";

//* api
import { api } from "@api";

//* components
import Header from "@components/common/Header";
import ConsultationList from "@components/counselor/ConsultationList";
import ConsultationSearchBar from "@components/counselor/ConsultationSearchBar";

//* func
import { func } from "@func";

function ConsultationReportPage() {
  //* state
  const [tableData, setTableData] = useState([]);
  const [searchData, setSearchData] = useState({
    user_name: "",
    way: "",
    start_date: func.formatDate(new Date()),
    end_date: func.formatDate(func.getNextMonthDate()),
  });

  useEffect(() => {
    historyConsultationList({ isFinish: true });
  }, []);

  const historyConsultationList = async (searchData) => {
    try {
      const historyConsultationListResponse = await api.POST_CONSULTATION_LIST(
        searchData
      );

      setTableData(historyConsultationListResponse.list);
    } catch (err) {
      console.log("상담 리포트 리스트 err", err);
    }
  };

  const memoizedTableData = useMemo(() => tableData, [tableData]);

  const consultationListSearch = () => {
    historyConsultationList({ ...searchData, isFinish: true });
  };

  return (
    <>
      <Header />
      <MainLayout>
        <ConsultationSearchBar
          searchData={searchData}
          setSearchData={setSearchData}
          consultationListSearch={consultationListSearch}
        />
        <ConsultationList type="report" tableData={memoizedTableData} />
      </MainLayout>
    </>
  );
}

export default ConsultationReportPage;
