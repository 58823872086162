// 공통 Data Table

//* hooks
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

function DataTable({
  type,
  columns,
  infoSeq,
  mainSeq,
  tableData,
  setMainSeq,
  setInfoSeq,
  deleteSeqList,
  setDeleteSeqList,
  setQuestionDiagnosisData,
}) {
  //* state
  const [data, setData] = useState(tableData);
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  useEffect(() => {
    if (deleteSeqList === "getList") {
      const selectedRows = table
        .getSelectedRowModel()
        .flatRows.map((row) => row.original);
      const selectedInfoSeq = selectedRows.map((row) => row.info_seq);
      setDeleteSeqList(selectedInfoSeq);

      table.toggleAllRowsSelected(false);
    }
  }, [deleteSeqList]);

  const navigate = useNavigate();

  const pageSize = () => {
    if (type === "questionBankModal") {
      return 20;
    } else if (type === "questionDiagnosisModal") {
      return 100;
    } else {
      return 10;
    }
  };

  const table = useReactTable({
    data,
    columns,
    state: { rowSelection },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: pageSize(),
      },
    },
  });

  const navigateToDetail = (id) => {
    if (type !== "report") {
      navigate(`/consultation/${id}`);
    } else {
      navigate(`/consultation-report/${id}`);
    }
  };

  const listClickHandler = (seq, mainSeq, infoSeq) => {
    if (
      type !== "questionBank" &&
      type !== "questionBankModal" &&
      type !== "questionDiagnosis" &&
      type !== "noticeKeyword"
    ) {
      navigateToDetail(seq);
    } else if (type === "questionBank" || type === "questionDiagnosis") {
      setMainSeq(mainSeq);
    } else if (type === "questionBankModal") {
      setInfoSeq(infoSeq);
    }
  };

  const dragItem = useRef();
  const dragOverItem = useRef();

  const isDraggable = type === "questionDiagnosisModal";

  // 드래그 시작될 때 실행
  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  // 드래그중인 대상이 위로 포개졌을 때
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  // 드랍 (커서 뗐을 때)
  const drop = (e) => {
    const newList = [...tableData];
    const dragItemValue = newList[dragItem.current];
    newList.splice(dragItem.current, 1);
    newList.splice(dragOverItem.current, 0, dragItemValue);
    dragItem.current = null;
    dragOverItem.current = null;
    setQuestionDiagnosisData(newList);
  };

  const listBackgroundHandler = (main, info) => {
    if (type === "questionBank" && mainSeq === main) {
      return true;
    } else if (type === "questionBankModal" && infoSeq === info) {
      return true;
    }
  };

  return (
    <>
      <CommonDataTable>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} style={{ width: header.getSize() }}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, idx) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  style={
                    listBackgroundHandler(
                      row.original.main_seq,
                      row.original.info_seq
                    ) && { background: "#f8faf0" }
                  }
                  onClick={(e) => {
                    if (
                      type !== "questionBankPool" &&
                      type !== "questionDiagnosisModal"
                    ) {
                      if (e.currentTarget.textContent === "편집") {
                        e.stopPropagation();
                        return;
                      }
                      listClickHandler(
                        row.original.seq,
                        row.original.main_seq,
                        row.original.info_seq
                      );
                    }
                  }}
                  draggable={isDraggable}
                  onDragEnd={isDraggable ? drop : undefined}
                  onDragStart={
                    isDraggable ? (e) => dragStart(e, idx) : undefined
                  }
                  onDragEnter={
                    isDraggable ? (e) => dragEnter(e, idx) : undefined
                  }
                  onDragOver={
                    isDraggable ? (e) => e.preventDefault() : undefined
                  }
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </CommonDataTable>
      {type !== "questionBankModal" &&
        type !== "questionDiagnosisModal" &&
        type !== "questionBankPool" && (
          <Pagination>
            <button
              onClick={() => table.firstPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {"<<"}
            </button>
            <button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {"<"}
            </button>
            {table.getPageOptions().map((page) => {
              const isActive = table.getState().pagination.pageIndex === page;
              return (
                <button
                  key={page}
                  onClick={() => table.setPageIndex(page)}
                  className={isActive ? "active" : ""}
                >
                  {page + 1}
                </button>
              );
            })}
            <button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {">"}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.lastPage()}
              disabled={!table.getCanNextPage()}
            >
              {">>"}
            </button>
            {/* <select
          style={{ margin: "5px" }}
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select> */}
          </Pagination>
        )}
    </>
  );
}

const CommonDataTable = styled.table`
  width: 100%;
  background-color: #fff;

  th,
  td {
    padding: 0.8rem 1rem;
    border-right: 1px solid ${({ theme }) => theme.color.border};
    border-bottom: 1px solid ${({ theme }) => theme.color.border};
  }

  th,
  td:last-child {
    border-right: none;
  }

  th {
    background-color: ${({ theme }) => theme.color.secondary};
    border-top: 1px solid ${({ theme }) => theme.color.border};
    border-right: 1px solid ${({ theme }) => theme.color.border};
  }

  th:last-child {
    border-right: none;
  }

  td {
    cursor: pointer;
  }
`;

const Pagination = styled.div`
  display: flex;
  margin: 1rem 0 2rem;
  justify-content: center;

  button {
    margin: 0 0.2rem;
    padding: 0.4rem 0.6rem;
    border: 1px solid ${({ theme }) => theme.color.border};
  }

  .active {
    background-color: ${({ theme }) => theme.color.secondary};
  }
`;

export default DataTable;
