// 설문관리 - 문제은행 페이지

//* hooks
import React from "react";
import { useState, useEffect } from "react";

//* style
import { MainLayout } from "@styles/common";

//* api
import { api } from "@api";

//* components
import Header from "@components/common/Header";
import QuestionBankList from "@components/counselor/QuestionBankList";
import QuestionSearchBar from "@components/counselor/QuestionSearchBar";
import QuestionBankModal from "@components/counselor/QuestionBankModal";
import QuestionBankContent from "@components/counselor/QuestionBankContent";

function QuestionBankPage() {
  // 검색
  const [searchData, setSearchData] = useState({
    use_yn: "",
    search_type: "question_name",
    search_value: "",
  });
  const [useType, setUseYn] = useState([]); // 사용 여부
  const [tableData, setTableData] = useState([]); // 리스트 데이터
  const [questionType, setQuestionType] = useState([]); // 문제 유형
  const [inspectionType, setInspectionType] = useState([]); // 검사 구분
  const [questionCategory, setQuestionCategory] = useState([]); // 문제 유형 구분
  // 문제은행 관리 데이터
  const [questionManage, setQuestionManage] = useState({
    use_yn: "", // 사용여부
    question_name: "", // 문제은행 명
    question_type: "", // 문제유형 (단답, 다답, 예아니오, 서술)
    inspection_type: "", // 검사구분
    question_category: "", // 문제유형 구분 (단일, 다중)
    question_info: "", // 문제은행 소개
  });
  const [mainSeq, setMainSeq] = useState(null); // 선택된 main eq
  const [infoSeq, setInfoSeq] = useState(null); // 선택된 info seq
  const [modalMainSeq, setModalMainSeq] = useState(null); // 편집 클릭한 seq
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false); // 모달 오픈 여부

  useEffect(() => {
    questionListRequest();
  }, []);

  useEffect(() => {
    if (mainSeq) {
      api.GET_MAIN_QUESTION_BANK_DETAIL(mainSeq).then((res) => {
        setQuestionManage(res);
      });
    }
  }, [mainSeq]);

  // 단일.다중 등록 변경시 문제 유형 비활성화 활성화
  useEffect(() => {
    if (questionType.length && !mainSeq) {
      // 다중등록일 경우
      if (questionManage.question_category === "PS01") {
        setQuestionManage((prev) => ({ ...prev, question_type: "" }));
      } else {
        setQuestionManage((prev) => ({
          ...prev,
          question_type: questionType[0].detail_code,
        }));
      }
    }
  }, [questionManage.question_category]);

  // 리스트 데이터 요청 함수
  const questionListRequest = async () => {
    try {
      const res = await api.POST_MAIN_QUESTION_BANK_LIST(searchData);
      if (res.status === 200) {
        setTableData(res.list);
        setUseYn(res.useTypeCodes);
        setQuestionType(res.questionTypeCodes);
        setInspectionType(res.inspectionTypeCodes);
        setQuestionCategory(res.questionCategoryCodes);

        setMainSeq(null);
        setQuestionManage({
          question_name: "",
          question_info: "",
          use_yn: res.useTypeCodes[0].detail_code,
          question_type: res.questionTypeCodes[0].detail_code,
          inspection_type: res.inspectionTypeCodes[0].detail_code,
          question_category: res.questionCategoryCodes[0].detail_code,
        });

        setSearchData((prev) => ({
          ...prev,
          use_yn: res.useTypeCodes[0].detail_code,
        }));
      }
    } catch (err) {
      console.error("문제은행 리스트 에러", err);
      return null;
    }
  };

  // 리스트 검색
  const questionListSearch = () => {
    api.POST_MAIN_QUESTION_BANK_LIST(searchData).then((res) => {
      setTableData(res.list);
    });
  };

  // 질문 추가
  const addMainQuestion = () => {
    setMainSeq(null);
    setQuestionManage({
      question_name: "",
      question_info: "",
      use_yn: useType[0].detail_code,
      question_type: questionType[0].detail_code,
      inspection_type: inspectionType[0].detail_code,
      question_category: questionCategory[0].detail_code,
    });
  };

  // 질문 저장
  const saveMainQuestion = async () => {
    // e.stopPropagation();
    if (!questionManage.question_name) {
      alert("문제은행명을 입력해주세요.");
      return;
    } else {
      try {
        const saveRequest = await api.POST_ADD_MAIN_QUESTION_BANK(
          questionManage
        );
        if (saveRequest.status === 201) {
          questionListRequest();
        }
      } catch (err) {
        console.log("main seq 저장 err", err);
      }
    }
    // return false;
  };

  // 질문 삭제
  const deleteMainQuestion = () => {
    if (mainSeq === null) {
      alert("삭제할 문제은행을 선택해주세요.");
      return;
    } else {
      if (window.confirm("문제를 삭제하시겠습니까?")) {
        api.DELETE_MAIN_QUESTION_BANK(mainSeq).then((res) => {
          if (res.status === 200) {
            questionListRequest();
          }
        });
      }
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <QuestionSearchBar
          useType={useType}
          type="questionBank"
          searchData={searchData}
          setSearchData={setSearchData}
          questionListSearch={questionListSearch}
        ></QuestionSearchBar>
        <QuestionBankList
          type="questionBank"
          mainSeq={mainSeq}
          tableData={tableData}
          setMainSeq={setMainSeq}
          setModalMainSeq={setModalMainSeq}
          setIsQuestionModalOpen={setIsQuestionModalOpen}
        />
        <QuestionBankContent
          mainSeq={mainSeq}
          useType={useType}
          questionType={questionType}
          inspectionType={inspectionType}
          questionManage={questionManage}
          addMainQuestion={addMainQuestion}
          questionCategory={questionCategory}
          saveMainQuestion={saveMainQuestion}
          setQuestionManage={setQuestionManage}
          deleteMainQuestion={deleteMainQuestion}
        />
        {isQuestionModalOpen && (
          <QuestionBankModal
            infoSeq={infoSeq}
            setInfoSeq={setInfoSeq}
            modalMainSeq={modalMainSeq}
            questionType={questionType}
            setModalMainSeq={setModalMainSeq}
            setIsQuestionModalOpen={setIsQuestionModalOpen}
          />
        )}
      </MainLayout>
    </>
  );
}

export default QuestionBankPage;
