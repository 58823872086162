// 내담자 - 상담신청

//* hooks
import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//* style
import { MainLayout, CommonButton } from "@styles/common";

//* api
import { api } from "@api";

//* components
import Hour from "@components/common/Hour";
import Header from "@components/common/Header";
import CalendarPicker from "@components/common/CalendarPicker";

function ConsultationApplicationPage() {
  //* state
  const [pickDate, setPickDate] = useState("");
  const [applicationInfo, setApplicationInfo] = useState({
    name: "",
    cellphone: "",
    reservationDate: "",
    hour: { hour: "", type: "" },
  });
  const [hour, setHour] = useState({ hour: "", type: "" });
  const [selectAmHourIdx, setSelectAmHourIdx] = useState(-1);
  const [selectPmHourIdx, setSelectPmHourIdx] = useState(-1);
  const [consultationData, setConsultationData] = useState({
    name: "",
    file: "",
    mode: "온라인",
    content: "",
  });

  const amHour = ["09", "10", "11"];
  const pmHour = ["01", "02", "03", "04", "05"];
  const consultationModeList = ["온라인", "방문", "녹음(녹화)"];

  const navigate = useNavigate();

  useEffect(() => {
    myInfo();
  }, []);

  const myInfo = async () => {
    try {
      const myInfoResponse = await api.GET_MY_INFO();
      setApplicationInfo((prevState) => ({
        ...prevState,
        name: myInfoResponse.username,
        cellphone: myInfoResponse.cellphone,
      }));
    } catch (err) {
      console.log("내 정보 요청 err", err);
    }
  };

  const consultationApplicationRequest = async () => {
    const formData = new FormData();

    const counsel_dt = `${pickDate} ${
      selectAmHourIdx === -1
        ? Number(pmHour[selectPmHourIdx]) + 12
        : amHour[selectAmHourIdx]
    }:00`;

    formData.append("counsel_dt", counsel_dt);
    formData.append("subject", consultationData.name);
    formData.append("counsel_way", consultationData.mode);
    formData.append("request_details", consultationData.content);

    if (consultationData.file) {
      formData.append("file", consultationData.file);
    }

    try {
      const consultationApplicationResponse =
        await api.POST_CONSULTATION_APPLICATION(formData);

      if (consultationApplicationResponse.status === 201) {
        alert("상담신청이 되었습니다.");
        navigate("/my-consultation");
      }
    } catch (err) {
      console.log("상담신청 err", err);
    }
  };

  return (
    <>
      <Header />
      <MainLayout>
        <h2 id="applicationTitle">
          상담신청
          <span>
            *상담 예약 신청이 당일에는 예약이 안될 수 있으니 최소 2~3일 후로
            신청해주시기 바랍니다.
          </span>
        </h2>
        <ApplicationContainer>
          <div id="applicationWrap">
            <div id="applicationInfo">
              <div className="application-layout">
                <p className="key">성명</p>
                <p className="value">{applicationInfo.name}</p>
              </div>
              <div className="application-twin-layout">
                <div className="application-layout">
                  <p className="key">휴대전화 번호</p>
                  <p className="value">{applicationInfo.cellphone}</p>
                </div>
                <div className="application-layout">
                  <p className="key">상담방법</p>
                  <div className="application-mode">
                    {consultationModeList.map((mode) => (
                      <React.Fragment key={mode}>
                        <input
                          name="mode"
                          id={mode}
                          type="radio"
                          value={mode}
                          checked={consultationData.mode === mode}
                          onChange={(e) => {
                            e.target.value === "녹음(녹화)"
                              ? setConsultationData((prev) => ({
                                  ...prev,
                                  mode: e.target.value,
                                }))
                              : setConsultationData((prev) => ({
                                  ...prev,
                                  file: "",
                                  mode: e.target.value,
                                }));
                          }}
                        />
                        <label htmlFor={mode}>{mode}</label>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>

              {consultationData.mode === "녹음(녹화)" && (
                <div className="application-layout file-upload-container">
                  <p className="key">파일 업로드</p>
                  <input
                    type="file"
                    accept=".mp3, .mp4"
                    className="file-upload"
                    onChange={(e) =>
                      setConsultationData((prev) => ({
                        ...prev,
                        file: e.target.files[0],
                      }))
                    }
                  />
                  <span>
                    * 파일은 mp4 또는 mp3 형식으로 최대 300MB 용량의 파일을
                    업로드하여 주시기 바랍니다.
                  </span>
                </div>
              )}

              <div className="application-twin-layout">
                <div className="application-layout">
                  <p className="key">상담일시</p>
                  <CalendarPicker
                    choicePrevDay={false}
                    setPickDate={setPickDate}
                  />
                </div>
                <div className="application-layout">
                  <Hour
                    hour={hour}
                    setHour={setHour}
                    pickDate={pickDate}
                    selectAmHourIdx={selectAmHourIdx}
                    selectPmHourIdx={selectPmHourIdx}
                    setSelectAmHourIdx={setSelectAmHourIdx}
                    setSelectPmHourIdx={setSelectPmHourIdx}
                  />
                </div>
              </div>
              <div className="application-layout">
                <p className="key">상담명</p>
                <textarea
                  maxLength="30"
                  onChange={(e) =>
                    setConsultationData((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="application-layout">
                <p className="key">상담 요청 내용</p>
                <textarea
                  maxLength="200"
                  onChange={(e) =>
                    setConsultationData((prev) => ({
                      ...prev,
                      content: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>

          <CommonButton
            width={"20%"}
            fontSize={"1.2rem"}
            onClick={consultationApplicationRequest}
          >
            상담신청
          </CommonButton>
        </ApplicationContainer>
      </MainLayout>
    </>
  );
}

const ApplicationContainer = styled.div`
  display: flex;
  margin: 2rem 0rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  #applicationWrap {
    width: 80%;
  }

  #applicationInfo {
    .application-twin-layout {
      width: 100%;
      display: flex;
      justify-content: space-between;

      > div:nth-child(2) {
        width: 40%;
      }
    }

    .application-layout {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      margin-bottom: 2rem;

      .key {
        width: 12rem;
        font-weight: 600;
      }

      .file-upload {
        width: calc(100% - 12rem);
      }

      .application-mode {
        label {
          margin-right: 0.6rem;
        }
      }

      textarea {
        resize: none;
        font-size: 1.2rem;
        width: calc(100% - 12rem);
      }
    }

    .file-upload-container {
      position: relative;
      margin-bottom: 3rem;

      span {
        left: 12rem;
        bottom: -1.2rem;
        font-size: 0.8rem;
        position: absolute;
      }
    }
  }
`;

export default ConsultationApplicationPage;
