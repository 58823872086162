// 상담사 - 설문관리 검색

//* style
import { CommonButton, CommonSearchBar } from "@styles/common";

function QuestionSearchBar({
  type,
  useType,
  searchData,
  setSearchData,
  questionListSearch,
}) {
  return (
    <CommonSearchBar>
      <div>
        <label htmlFor="used">사용여부</label>
        <select
          id="used"
          value={searchData.used}
          onChange={(e) =>
            setSearchData((prev) => ({
              ...prev,
              use_yn: e.target.value,
            }))
          }
        >
          {useType.map((yn) => (
            <option key={yn.code_name} value={yn.detail_code}>
              {yn.code_name}
            </option>
          ))}
        </select>
      </div>
      {type === "questionBank" ? (
        <div>
          <label htmlFor="division">구분</label>
          <select
            id="used"
            value={searchData.search_type}
            onChange={(e) =>
              setSearchData((prev) => ({
                ...prev,
                search_type: e.target.value,
              }))
            }
          >
            <option value="question_name">문제은행명</option>
            <option value="user_name">출제자</option>
          </select>
          <input
            type="text"
            id="division"
            placeholder="검색어를 입력해 주세요."
            onChange={(e) =>
              setSearchData((prev) => ({
                ...prev,
                search_value: e.target.value,
              }))
            }
          />
        </div>
      ) : (
        <div>
          <label htmlFor="diagnosis_title">진단검사명</label>
          <input
            type="text"
            id="diagnosis_title"
            placeholder="검색어를 입력해 주세요."
            onChange={(e) =>
              setSearchData((prev) => ({
                ...prev,
                search_value: e.target.value,
              }))
            }
          />
        </div>
      )}

      <CommonButton width="6rem" onClick={questionListSearch}>
        검색
      </CommonButton>
    </CommonSearchBar>
  );
}
export default QuestionSearchBar;
